import React from 'react';
import axios from "axios";
import {Link} from 'react-router-dom';
import {Row,Col,Container,Form,Tab,Nav,Image} from 'react-bootstrap';
import CheckoutItem from './common/CheckoutItem';
import BestSeller from './common/BestSeller';
import LoginModal from './modals/LoginModal';
import Icofont from 'react-icofont';

class Index extends React.Component {
	_isMounted = false;

	constructor(props, context) {
	    super(props, context);

	    this.state = {
      	  showAddressModal: false,
		  showLoginModal: false,
		  foodCategory: [],
		  foodItems: [],
		  serving: [],
		  sauces: [],
		  mediaUrl: props.apiUrl.replace("api/", ""),
	    };
	}
	
	hideLoginModal = () => this.setState({ showLoginModal: false });

	componentDidMount() {
		this._isMounted = true;

		this.getMenusDetails();

		const interval = setInterval(this.getMenusDetails, 10000);
	  
		return () => {
			clearInterval(interval);
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state,callback)=>{
			return;
		};
	}

	getMenusDetails = () => {
		axios({
            url: this.props.apiUrl+"get-menus-details",
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            }
        })
        .then((res) => {
            if(res.data.status) {
				if(this._isMounted) {
					this.setState({foodCategory: res.data.categories});
					this.setState({foodItems: res.data.menus});
					this.setState({serving: res.data.serving});
					this.setState({sauces: res.data.sauces});
				}
            } else {
                console.log(res.data.message);
            }
        })
        .catch((err) => {
            console.log(err.message);
            console.log("Api error! Please try later.");
        });
	}

    getQty = ({id,quantity}) => {
		//Check parameters
    	//console.log(id);
    	//console.log(quantity);
	}


	//Split components and functions

	isShopCloseClass = this.props.shopOpen?"":"shop-close";

	itemServing = (item) => {
		return item.serving?item.serving.split(","):[];
	}

	itemServes = (item, curItem) => {
		let serving = item.serving?item.serving.split(","):[];

		if(curItem) {
			if(curItem.serving) {
				return curItem.serving;
			}
		}

		return item.serving?serving[0]:"";
	}

	filterServing = (serving) => {
		return serving?this.state.serving.filter(serve => serving.includes(serve.id.toString())):[];
	}

	itemSauces = (item) => {
		return item.sauces?item.sauces.split(","):[];
	}

	filterSauces = (sauces) => {
		return sauces?this.state.sauces.filter(sauce => sauces.includes(sauce.id.toString())):[];
	}

	itemQtys = (curItem) => {
		return curItem?curItem.quantity:0;
	}

	itemSizes = (curItem, variant, variantItemSize) => {		
		let defaultItemSize = (variant.length > 1?variantItemSize:"");
		return curItem?curItem.size:defaultItemSize;
	}

	curItemSauce = (curItem) => {
		const sauces = (curItem) => {
			return curItem.sauces?curItem.sauces:[];
		}
		
		return curItem?sauces(curItem):[];
	}

	sizeVariant = (variant) => {
		return variant.small?"small":"medium";
	}

	itemImage = (item) => {
		return item.image?this.state.mediaUrl+item.image:'img/list/1.png';
	}

	itemServeLimit = (item) => {
		return item.choose_limit?item.choose_limit:0;
	}

	render() {
		//Check parameters
		//console.log(this.props.cart);

    	return (
		<>
          <LoginModal show={this.state.showLoginModal} onHide={this.hideLoginModal} themeName={this.props.themeName}/>
    	  <section className="restaurant-detailed-banner">
	         <div className="text-center">
	            <Image fluid className="cover" src="img/home-banner.jpg" />
	         </div>
	         <div className="restaurant-detailed-header">
	            <Container>
	               <Row className="d-flex align-items-end">
	                  <Col md={12}>
	                     {/* <div className="restaurant-detailed-header-left">
	                        <h2 className="text-white">Spice Hut Indian Restaurant</h2>
	                        <p className="text-white mb-1">2036 2ND AVE, NEW YORK, NY 10029 <Badge  variant="success">OPEN</Badge>
	                        </p>
	                     </div> */}
	                  </Col>
	               </Row>
	            </Container>
	         </div>	          
	      </section>

          <Tab.Container defaultActiveKey={"cat0"}>
	      	<section className="offer-dedicated-nav bg-white border-top-0 shadow-sm">
		         <Container>
		            <Row>
		               <Col md={12}>
		                  <Nav id="pills-tab">
						  	{this.state.foodCategory.map((cat, index) => {
								let keyIdx = index + 1;
								return (
								<Nav.Item key={keyIdx}>
									<Nav.Link eventKey={"cat"+index}>{cat.name}</Nav.Link>
								</Nav.Item>
							)
							})}
		                  </Nav>
		               </Col>
		            </Row>
		         </Container>
	      	</section>

		    <section className="offer-dedicated-body pt-2 pb-2 mt-4 mb-4">
		        <Container>
		            <Row className={this.isShopCloseClass}>
		                <Col md={8}>
	                  		<div className="offer-dedicated-body-left">
							    <Tab.Content className='h-100'>
									{this.state.foodCategory.map((cat, index) => {
										let keyIdx = index + 1;
										return (
									<Tab.Pane eventKey={"cat"+index} key={keyIdx}>
										<Row>
											{this.state.foodItems.filter(item => +item.category === cat.id).map((item, index) => {
												const curItem = this.props.cart.find((cartItem) => cartItem.id === item.id);
												let itemQty = this.itemQtys(curItem); 
												let itemServe = this.itemServes(item, curItem);
												
												let serving = this.itemServing(item);
												let sauces = this.itemSauces(item);

												let itemSauce = this.curItemSauce(curItem);
												let variant = JSON.parse(item.price);
												let variantItemSize = this.sizeVariant(variant);
												let itemSize = this.itemSizes(curItem, variant, variantItemSize);

												return (
											<Col md={4} sm={6} className="mb-4" key={item.id}>
												<BestSeller
													id={item.id}
													title={item.name}
													subTitle={this.state.foodCategory.find((cat) => cat.id === +item.category).name}
													imageAlt={item.name}
													image={this.itemImage(item)}
													imageClass='img-fluid item-img'
													price={variant}
													priceUnit={this.props.settings.currency}
													qty={itemQty}
													size={itemSize}
													serving={this.filterServing(serving)}
													sauces={this.filterSauces(sauces)}
													choiceLimit={this.itemServeLimit(item)}
													itemServe={+itemServe}
													itemSauce={itemSauce}
													getValue={this.getQty}
													cart={this.props.cart} 
													addToCart={this.props.addToCart} 
													removeFromCart={this.props.removeFromCart}
													updateItemSize={this.props.updateItemSize}
												/>
											</Col>
											)})}
										</Row>
									</Tab.Pane>
									)
									})}
						        </Tab.Content>
						    </div>
						</Col>
		                <Col md={4}>		               
                            <div className="generator-bg rounded shadow-sm mb-4 p-4 osahan-cart-item">
                                <h5 className="mb-1 text-primary">Schedule Pick-up</h5>
                                <div className="pt-2"></div>
								<Form.Group className="pick_timer mb-1">
									<Icofont icon='clock-time'/>
									<Form.Control as="select" name="pick_time" disabled={this.props.curPickTime==="Closed"} onChange={(e)=> {this.props.setPickTime(e.target.value);}}>
										{this.props.pickTime.map((time, index) => {
											let keyIdx = index + 1;
											if(this.props.pastTime > index) {
												return null; //Unused code (<option value={time} key={index} disabled>{time}</option>);
											}
											return (<option value={time} key={keyIdx} selected={this.props.curPickTime===time}>{time}</option>);
										})}
									</Form.Control>
								</Form.Group>
								<p className="text-danger mb-0">Please select pick up time.</p>
                                <div className="pt-3"></div>
                                <h5 className="mb-1 text-primary">Your Order</h5>
                                <div className="pt-2"></div>
                                <div className="bg-white rounded mb-2"> {/*shadow-sm*/}
									{this.props.cart.map((item, index) => (
										<CheckoutItem 
											key={item.id}
											id={item.id}
											itemName={item.title}
											price={item.price}
											size={item.size}
											priceUnit={this.props.settings.currency}
											qty={item.quantity}
											show={true}
											getValue={this.getQty}
											cart={this.props.cart} 
											addToCart={this.props.addToCart} 
											removeFromCart={this.props.removeFromCart}
										/>
									))}                             
                                </div>
                                <div className="mb-2 bg-white rounded p-2 clearfix">
                                    <Image fluid className="float-left" src="img/wallet-icon.png" />
                                    <h6 className="text-right mb-2">Subtotal : <span className="text-primary">{this.props.settings.currency+this.props.cartTotal}</span></h6>
                                    <p className="seven-color mb-1 text-right">Extra charges may apply</p>
                                </div>
                                <Link to="/checkout" className={"btn btn-primary btn-block btn-lg"+((this.props.curPickTime!=="Closed" && this.props.cart.length>0)?"":" btn-disabled")} onClick={(e) => {if(!this.props.loggedIn) {e.preventDefault(); this.setState({ showLoginModal: true });} }}>Checkout</Link>
                            </div>

							<div className="generator-bg rounded shadow-sm mb-4 p-4 osahan-cart-item">
                                <h5 className="mb-1 text-primary">Allergens</h5>
								<p className="mb-0"><strong>Some of our dishes contain:</strong> dairy products & derivatives, eggs, seafood, gluten, barley, wheats, oats, soya, mustard, nuts & derivatives and sesame. If you have a food allergy, intolerance or sensitivity, please speak to our staff before you order. Thank you.</p>
							</div>
		                </Col>
					</Row>
				</Container>
		    </section>
	      </Tab.Container>
          
            <section className="section pt-5 pb-5 bg-white about-section">
                <Container>
                    <Row>
                        <Col md={6} sm={12}>
                            <h2 className="m-0 text-primary">About Us</h2>
                            <p></p>
                            {/*<p>Here at {this.props.themeName}, we are constantly striving to improve our service and quality in order to give our customers the very best experience. As a result, we are finally proud to unveil and introduce our latest improvement, our new online ordering website!</p>
                            <p>We’re passionate about providing delicious and high-quality food, our priority is to satisfy all of our customers needs, we offer a variety of excellent food, prepared with the freshest and finest ingredients, to the highest standard.</p>*/}
							<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Massa id neque aliquam vestibulum. Bibendum ut tristique et egestas quis ipsum suspendisse ultrices. Enim nulla aliquet porttitor lacus luctus accumsan tortor. Non nisi est sit amet facilisis magna etiam. Risus commodo viverra maecenas accumsan lacus vel. Arcu risus quis varius quam quisque id diam. Nunc sed velit dignissim sodales. Sed viverra tellus in hac. Id neque aliquam vestibulum morbi blandit cursus risus at. Ipsum dolor sit amet consectetur adipiscing elit pellentesque. Arcu risus quis varius quam. A condimentum vitae sapien pellentesque habitant. Non sodales neque sodales ut etiam sit. Urna et pharetra pharetra massa massa. Tellus pellentesque eu tincidunt tortor aliquam nulla facilisi. Pellentesque adipiscing commodo elit at imperdiet dui accumsan sit.</p>
                        </Col>
                        <Col md={6} sm={12} className="">
                            <Image src="img/about-us-banner.jpg" alt="" />
                        </Col>
                    </Row>
                </Container>
            </section>
	    </>
    	);
    }
}

export default Index;