import React from 'react';
import axios from "axios";
import {format} from 'date-fns';
import {Row,Col} from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import Icofont from 'react-icofont';
import {Link, Redirect} from 'react-router-dom';

class Orders extends React.Component {
	_isMounted = false;

	constructor(props, context) {
	    super(props, context);

	    this.state = {
      	  orderData: [],
	    };
	}

	componentDidMount() {
		this._isMounted = true;

		this.getOrders();

		const interval = setInterval(() => {
			this.getOrders();
		}, 10000);
	  
		return () => {
			clearInterval(interval);
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state,callback)=>{
			return;
		};
	}

	getOrders = () => {
		const userDetails = JSON.parse(localStorage.getItem("cuser"));
		const cid = userDetails.id?userDetails.id:0;
		
		axios({
            url: this.props.apiUrl+"my-orders/"+cid,
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
				"Authorization": localStorage.getItem("clogtoken")
            }
        })
        .then((res) => {
            if(res.data.status) {
				if(this._isMounted) {
					this.setState({orderData: res.data.orders});
				}
            } else {
                console.log(res.data.message);
            }
        })
        .catch((err) => {
            console.log(err.message);
            console.log("Api error! Please try later.");
        });
	}

	actionButtons = (row) => (
		<>
			<Link to={"/invoice/"+row.id} className="text-success"><Icofont icon="eye"/></Link> 
			{/*&nbsp;&nbsp;&nbsp;<Link to="#" className="text-danger"><Icofont icon="trash"/></Link>*}
			{/* <button
				className="btn btn-outline btn-xs"
				onClick={(e) => handleButtonClick(e, row.id)}
			><Icofont icon="edit"/></button> */}
		</>
	);

	columns = [{
		name: 'Order Id',
		selector: row => row.id,
		maxWidth: '100px'
	},
	// {
	// 	name: 'Customer Name',
	// 	selector: row => row.name,
	// },
	// {
	// 	name: 'Email',
	// 	selector: row => row.email,
	// },
	// {
	// 	name: 'Mobile',
	// 	selector: row => row.mobile,
	// },
	{
		name: 'Total',
		selector: row => this.props.settings.currency+row.grand_total,
		right: true,
	},
	{
		name: 'Placed At',
		selector: row => format(new Date(row.created_at), 'dd/MM/yyyy kk:mm:ss'),
		width: '180px'
	},
	{
		name: 'Pick-up/Delivery',
		selector: row => row.time,
	},
	{
		name: 'Status',
		selector: row => row.orderStatus,
	},
	{
		name: 'Actions',
		button: true,
		cell: (row) => this.actionButtons(row),
	}];	

	render() {
		if(!this.props.loggedIn) {
			return <Redirect to="/login" />
		}

    	return (
    		<>
				<Row>
					<Col md={12}>
						<div id="book-a-table" className="bg-white rounded shadow-sm p-4">
							<h5 className="text-primary mb-4">My Orders</h5>
    		    			<DataTable columns={this.columns} data={this.state.orderData} pagination paginationPerPage={25} paginationRowsPerPageOptions={[10, 25, 50, 100]}/>					
						</div>
					</Col>
				</Row>
		    </>
    	);
    }
}

export default Orders;