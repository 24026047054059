import React from 'react';
import {Modal,Form,Button} from 'react-bootstrap';

class SidesExtrasModal extends React.Component {
    checkServing = (serve) => {
      this.props.addServing(serve);
    }
  
    // toggleSaucesOptions = (toggleId) => {
    //   if(document.querySelector("#"+toggleId).classList.contains("opened"))
    //     document.querySelector("#"+toggleId).classList.remove("opened");
    //   else
    //     document.querySelector("#"+toggleId).classList.add("opened");    
    // } onClick={() => this.toggleSaucesOptions("saucesToggle"+this.props.itemProps.id)}
  
    checkChoiceLimit = (choice) => {
      const sauce = [];
  
      if(document.querySelectorAll(choice+":checked").length >= this.props.itemProps.choiceLimit) {
        document.querySelectorAll(choice).forEach((option) => {
          if(!option.checked) {
            option.setAttribute("disabled", true);
            option.parentElement.classList.add("disabled");
          } else {
            sauce.push(option.value);
          }
        });
      } else {
        document.querySelectorAll(choice).forEach((option) => {
          option.removeAttribute("disabled");
          option.parentElement.classList.remove("disabled");
  
          if(option.checked) {
            sauce.push(option.value);
          }
        });
      }
  
      this.props.addSauces(sauce);
    }

    checkedSauce = (sauce, choice) => {
        if(document.querySelectorAll(choice+":checked").length >= this.props.itemProps.choiceLimit) {
            document.querySelectorAll(choice).forEach((option) => {
              if(!option.checked) {
                option.setAttribute("disabled", true);
                option.parentElement.classList.add("disabled");
              }
            });
        }

        return (this.props.itemProps.itemSauce.indexOf(sauce.toString()) !== -1);
    }

	render() {
    return (
      <Modal 
            show={this.props.show} 
            onHide={this.props.onHide}
            size="sm"
            centered
        >
            <Modal.Header closeButton={true}>
                <Modal.Title as='h6'>{this.props.itemProps.title}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {this.props.itemProps.serving.length>0?(<p className="text-dark mb-2">Choice of Serving</p>):''}
                {this.props.itemProps.serving.length>0?(<div className="toggle-dropdown">{this.props.itemProps.serving.map((serve, index) => {
                  let keyIdx = index + 1;
                  return (
                <label key={keyIdx} className="custom-radio mb-1"><Form.Control type="radio" className={"serving"+this.props.itemProps.id} name={"serving"+this.props.itemProps.id} defaultValue={serve.id} defaultChecked={(+this.props.itemProps.itemServe) === (+serve.id)} onClick={() => this.checkServing(document.querySelector("input[name=serving"+this.props.itemProps.id+"]:checked").value)}></Form.Control> {serve.name}</label>
                )
                })}</div>):""}

                {this.props.itemProps.sauces.length>0?(<div className="toggle-dropdown mt-2" id={"saucesToggle"+this.props.itemProps.id}><p className="text-dark mb-0 toggle-header">Choice of Sauces</p><span className="mb-2">(Choose up to {this.props.itemProps.choiceLimit})</span>{this.props.itemProps.sauces.map((sauce, index) => {
                  let keyIdx = index + 1;
                  return (
                <label key={keyIdx} className="custom-checkbox mb-1"><Form.Control type="checkbox" className={"sauces"+this.props.itemProps.id} name={"sauces"+this.props.itemProps.id+"[]"} defaultValue={sauce.id} defaultChecked={this.checkedSauce(sauce.id, ".sauces"+this.props.itemProps.id)} onClick={() => this.checkChoiceLimit(".sauces"+this.props.itemProps.id)}></Form.Control> {sauce.name}</label>
                )
                })}</div>):''}

                <Button type='button' onClick={this.props.onHide} className="btn btn-primary btn-sm pull-right">OK</Button>
            </Modal.Body>
      </Modal>
    );
  }
}
export default SidesExtrasModal;