import React from 'react';
import axios from "axios";
import {format} from 'date-fns';
import {Switch, Route, Redirect} from 'react-router-dom';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import MessageBox from './components/MessageBox';
import Index from './components/Index';
import ContactUs from './components/ContactUs';
import MyAccount from './components/MyAccount';
import List from './components/List';
import NotFound from './components/NotFound';
import Thanks from './components/Thanks';
import Extra from './components/Extra';
import Login from './components/Login';
import OTPVerify from './components/OTPVerify';
import Register from './components/Register';
import TrackOrder from './components/TrackOrder';
import Invoice from './components/Invoice';
import Checkout from './components/Checkout';
import Detail from './components/Detail';
import PrivacyPolicy from './components/PrivacyPolicy';
import CookiesPolicy from './components/CookiesPolicy';
import TermsConditions from './components/TermsConditions';
import 'typeface-poppins';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'react-select2-wrapper/css/select2.css';
import './App.css';
import './Custom.css';

class App extends React.Component { 
	_isMounted = false;

  constructor() {
    super();
    this.state = {
      themeName: "Restaurant App",
      themeLogo: "logo.png",
      themeFavicon: "favicon.png",
      themeColor: "#4169e1",
      pickTime: [],
      loggedIn: (localStorage.getItem("uloggedIn") === "true") || false,
      cart: JSON.parse(localStorage.getItem("cartFoodItems")) || [],
      cartTotal: localStorage.getItem("cartTotalPrice") || 0,
      curPickTime: localStorage.getItem("curPickTime") || null,
      curPickDate: localStorage.getItem("curPickDate") || null,
      pastTime: parseInt(localStorage.getItem("pastTime")) || 0,
      settings: {},
		  shopOpen: true,
      apiUrl: process.env.REACT_APP_API_URL
    };
  }

  componentDidMount() {
    this._isMounted = true;

    this.getSettings();
    this.getCurPickTime();
    
		const interval = setInterval(() => {
      this.getSettings();
      this.getCurPickTime();
    }, 10000);

    setTimeout(() => {
      const lang = this.readLangCookies()?this.readLangCookies():this.state.settings.language;
      if(document.querySelector(".goog-te-combo") && lang) {
        if(document.querySelector(".goog-te-combo option[value='"+lang+"']")
        && document.querySelector(".goog-te-combo option[value='"+lang+"']").getAttribute("selected")!==true) {
          document.querySelector(".goog-te-combo option[value='"+lang+"']").setAttribute("selected", true);
          document.querySelector(".goog-te-combo").dispatchEvent(new Event('change'));
        }
      }
    }, 2000);

    return () => clearInterval(interval);
	}

  componentWillUnmount() {
    this._isMounted = false;
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
        return;
    };
  }

  readLangCookies = () => {
		let cookies = document.cookie;
		let cookieArr = cookies.split("; ");
		let cookieIdx = cookieArr.findIndex(ele => ele.includes("googtrans"));

		if(cookieIdx >= 0) {
			let lang = cookieArr[cookieIdx];
			lang = lang.split("=");
			lang = lang[1].split("/");

			return lang[2];
		} else {
			return null;
		}
	}

	getSettings = () => {
		axios({
        url: this.state.apiUrl+"get-settings",
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        }
    })
    .then((res) => {
        if(res.data.status) {
          const curDate = new Date();
          let curDay = curDate.getDay()===0?7:curDate.getDay();
          const settings = {
            timeInterval: +res.data.settings.time_interval,
            pauseOrder: +res.data.settings.pause_order,
            pauseOrderStartTime: res.data.settings.pause_order_start_time,
            openingDay: res.data.openingHours[curDay - 1],
            openingHours: res.data.openingHours,
            holidays: res.data.holidays,
            currency: res.data.settings.currency+" ",
            vat: +res.data.settings.vat/100,
            taxDesc: res.data.settings.tax_desc,
            otp_login: +res.data.settings.otp_login === 1,
            email: res.data.settings.email,
            phone: res.data.settings.phone,
            address: res.data.settings.address,
            country: res.data.settings.country,
            language: res.data.settings.language,
            country_code: res.data.settings.country_code
          };

          if(this._isMounted) {
            this.setState({themeName: res.data.settings.app_name});
            this.setState({themeLogo: res.data.settings.theme_logo});
            this.setState({themeFavicon: res.data.settings.theme_favicon});
            this.setState({themeColor: res.data.settings.theme_color});
            this.setState({settings: settings});
          }

          this.checkOpenCloseTime();
          this.getPickTimeList();
        } else {
          console.log(res.data.message);
        }
    })
    .catch((err) => {
        console.log(err.message);
        console.log("Api error! Please try later.");
    });
	}
  
  hexToRgb = (hex) => {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? (parseInt(result[1], 16) +','+ parseInt(result[2], 16) +','+ parseInt(result[3], 16)) : null;
  }

  applyThemeColor = (themeColor) => {
    let cssText=`.offer-dedicated-nav .nav-link.active, .offer-dedicated-nav .nav-link:hover, .offer-dedicated-nav .nav-link:focus, .text-primary, #orderStatus .order-status.active, .contact-section table tr.active th, .contact-section table tr.active td, .btn-outline-primary, #registerForm a, #loginForm a, .osahan-account-page-left .nav-link.active, p a, .upload-profile-pic-form label {
      color: ${themeColor} !important;
    }
    .btn-primary, .badge-primary, .shop-close::after, .btn-outline-primary:hover, .btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show>.btn-outline-primary.dropdown-toggle, #orderStatus .order-status.active i, #orderStatus .order-status.active::before, .dropdown-item:focus, .dropdown-item.active, .osahan-account-page-left .nav-link.active i, .dropdown-item:active, #rcc-confirm-button {
      background-color: ${themeColor} !important;
    }
    .btn-primary, .offer-dedicated-nav .nav-link.active, .offer-dedicated-nav .nav-link:hover, .offer-dedicated-nav .nav-link:focus, .btn-outline-primary, .btn-outline-primary:hover, .btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show>.btn-outline-primary.dropdown-toggle, .dropdown-cart-top, .form-control:focus {
      border-color: ${themeColor} !important;
    }
    .btn-outline-primary.focus, .btn-outline-primary:focus, .btn-primary.focus, .btn-primary:focus, .form-control:focus {
      box-shadow: 0 0 0 0.2rem rgba(${this.hexToRgb(themeColor)},.5) !important;
    }`;

    if(document.querySelector("#dynamicStyles") === null) {
      let styles=document.createElement('style');
      styles.id="dynamicStyles";
      styles.textContent=cssText;
      document.getElementsByTagName('head')[0].appendChild(styles);
    } else {
      document.querySelector("#dynamicStyles").textContent=cssText;
    }
  }

  setShopCloseState = (status) => {
    if(this._isMounted) {
			this.setState({shopOpen: status});
    }
  }

  isShopCloseTime = (condition) => {
    if(this._isMounted) {
      if(condition) {
        this.setState({shopOpen: false});
        return true;
      }
      this.setState({shopOpen: true});
      return false;
    }
    return false;
  }
  
  checkOpenCloseTime = () => {
		if(this.state.settings.openingDay) {
      // Split open and close time
			// const open = this.state.settings.openingDay.open.split(":");
			// const close = this.state.settings.openingDay.close.split(":");
      const date = new Date();
			const curdate = format(date, 'yyyy-MM-dd');

      let open = curdate+' '+this.state.settings.openingDay.open+':00';
			let close = curdate+' '+this.state.settings.openingDay.close+':00';

      open = new Date(open).getTime();
      close = new Date(close).getTime();

      // Get time in hours and minutes
			// const curHrs = +date.getHours();
			// const curMin = +date.getMinutes();
      const curTime = date.getTime();

			const pauseOrder = +this.state.settings.pauseOrder;
			const pauseTime = this.state.settings.pauseOrderStartTime;

			if(this.state.settings.holidays.length && this.state.settings.holidays.filter((holiday) => holiday.date === curdate).length) {
        this.setShopCloseState(false);
        return true;
			} else if(open <= curTime && close >= curTime) {
        //(+open[0] <= curHrs && (+open[0] < curHrs || +open[1] <= curMin)) || (+close[0] >= curHrs && (+close[0] > curHrs || +close[1] >= curMin))
				if(pauseOrder !== 0) {
					if(pauseOrder !== -1) {
						const pauseStartTime = new Date(pauseTime).getTime();
						const pauseEndTime = new Date(new Date(pauseTime).getTime() + (pauseOrder * 60000)).getTime();
            //Get current time
						//const curTime = new Date().getTime();						
            this.isShopCloseTime(pauseStartTime <= curTime && pauseEndTime >= curTime);
					}
          const pauseDay = format(new Date(pauseTime), 'yyyy-MM-dd');
          this.isShopCloseTime(pauseDay === curdate);
				}
        this.setShopCloseState(true);
        return false;
			}
      this.setShopCloseState(false);
      return false;
		}
    return false;
	}

  getPickTimeList = () => {
    const open = this.state.settings.openingDay.open;
		const close = this.state.settings.openingDay.close;
    const curdate = format(new Date(), 'yyyy-MM-dd');

    let date = new Date(curdate+" "+open+":00");
    const intervalMinutes = +this.state.settings.timeInterval;
    const dom = new Date(curdate+" "+close+":00");;
    let times = [];

    do {
        times.push(date.toLocaleTimeString("en-US", { hourCycle: 'h23', hour: '2-digit', minute: '2-digit' }))
        date = new Date(date.setMinutes(date.getMinutes() + intervalMinutes));
    } while ((date.getHours() < dom.getHours()) || ((date.getHours() === dom.getHours()) && (date.getMinutes() < dom.getMinutes())))

    times.push("Closed");
    if(this._isMounted)
      this.setState({pickTime: times});

    if(times.indexOf(this.state.curPickTime) === -1) {
      if(this._isMounted)
        this.setState({pastTime: 0});

      localStorage.setItem("pastTime", 0);
    } else {
      if(this._isMounted)
        this.setState({pastTime: times.indexOf(this.state.curPickTime)});

      localStorage.setItem("pastTime", times.indexOf(this.state.curPickTime));
    }
    this.getCurPickTime();
    //Check values
    //console.log(date.getHours(), times);
  }

  getParams = (url, pos) => {
    let params = url.split("//");
    params = params[1].split("/");
    return params[pos+1];
  }

  setPickTime = (time) => {
    localStorage.setItem("curPickTime", time);
    localStorage.setItem("curPickDate", format(new Date(), 'yyyy-MM-dd'));
    localStorage.setItem("pastTime", this.state.pickTime.indexOf(time));

    this.setState({curPickTime: time});
    this.setState({curPickDate: format(new Date(), 'yyyy-MM-dd')});
    this.setState({pastTime: this.state.pickTime.indexOf(time)});
  }

  setTimeIterval = () => {
    return this.state.settings.timeInterval?+this.state.settings.timeInterval:30;
  }

  getHours = (date, minutes) => {
    let hour = date.getHours();
    if(minutes === 60) {
      hour = hour+1; 
    } else if(minutes > 60) {
      hour = hour+1;
    } else if(minutes < 60) {
      //Code here
    }

    return hour;
  }

  getMinutes = (minutes) => {
    if(minutes === 60) {
      //Code here
    } else if(minutes > 60) {
      minutes = minutes - 60;
    } else if(minutes < 60) {
      //Code here
    }

    return minutes;
  }

  setTimes = () => {
    if(this.state.curPickDate && this.state.curPickDate !== format(new Date(), 'yyyy-MM-dd')) {
      localStorage.setItem("curPickTime", null);
      localStorage.setItem("pastTime", 0);
      localStorage.setItem("curPickDate", format(new Date(), 'yyyy-MM-dd'));

      if(this._isMounted) {
        this.setState({curPickTime: null});
        this.setState({pastTime: 0});
        this.setState({curPickDate: format(new Date(), 'yyyy-MM-dd')});
      }
    }
  }

  getTimeIdx = (relTime, minutes) => {
    let curIdx = -1;
    
    relTime.forEach((time) => {
      const min = +(time.split(":")[1]);
      if(minutes <= min) {
        curIdx = this.state.pickTime.indexOf(time);
      }
    });

    if(curIdx === -1) {
      const relVal = this.state.pickTime.indexOf(relTime[relTime.length - 1]) + 1;

      if(this.state.pickTime[relVal]) {
        curIdx = relVal;

        localStorage.setItem("pastTime", curIdx);
        if(this._isMounted)
          this.setState({pastTime: curIdx});
      }
    }

    return curIdx;
  }

  setPickPastTime = (curIdx) => {
    if(this.state.curPickTime===null || (this.state.pickTime.indexOf(this.state.curPickTime) < curIdx))
    {
      if(curIdx !== -1) //this.state.pickTime.indexOf(setTime)
      {
        localStorage.setItem("curPickTime", this.state.pickTime[curIdx]);
        localStorage.setItem("pastTime", curIdx);

        if(this._isMounted) {
          this.setState({curPickTime: this.state.pickTime[curIdx]});
          this.setState({pastTime: curIdx});
        }
      } else {
        localStorage.setItem("curPickTime", "Closed");
        localStorage.setItem("pastTime", (this.state.pickTime.length - 1));

        if(this._isMounted) {
          this.setState({curPickTime: "Closed"});
          this.setState({pastTime: (this.state.pickTime.length - 1)});
        }
      }
    }
  }

  getCurPickTime = () => {
    let date = new Date();
    let interval = this.setTimeIterval;
    let minutes = date.getMinutes() + interval;
    let hour = this.getHours(date, minutes);

    minutes = this.getMinutes(minutes);
    
    this.setTimes();

    const relTime = this.state.pickTime.filter((time) => time.includes(hour+':'));
    let curIdx = this.getTimeIdx(relTime, minutes);
    
    if(curIdx !== -1 && this.state.curPickTime === "Closed") 
    {      
      localStorage.setItem("curPickTime", this.state.pickTime[curIdx]); //setTime
      localStorage.setItem("pastTime", curIdx);

      if(this._isMounted) {
        this.setState({curPickTime: this.state.pickTime[curIdx]});
        this.setState({pastTime: curIdx});
      }
    } else {
      this.setPickPastTime(curIdx);
    }
  }

  UpdateItemSize = (item, type) => {
    const itemExist = this.state.cart.find((cartItem) => cartItem.id === item.id);
    if(itemExist) {
      switch(type) {
        case 'size': 
          this.setState({cart:
            this.state.cart.map((cartItem) =>
              cartItem.id === item.id ? {...itemExist, size: item.size, price: item.price} : cartItem
            )
          });
          break;
        case 'serve':
          this.setState({cart:
            this.state.cart.map((cartItem) =>
              cartItem.id === item.id ? {...itemExist, serving: item.serving} : cartItem
            )
          });
          break;
        case 'sauce':
          this.setState({cart:
            this.state.cart.map((cartItem) =>
              cartItem.id === item.id ? {...itemExist, sauces: item.sauces} : cartItem
            )
          });
          break;
        default:
          //No Code
      }

      // Remove this code and use alternate
      // if(type==="size") {
      //   this.setState({cart:
      //     this.state.cart.map((cartItem) =>
      //       cartItem.id === item.id ? {...itemExist, size: item.size, price: item.price} : cartItem
      //     )
      //   });
      // } else if(type==="serve") {
      //   this.setState({cart:
      //     this.state.cart.map((cartItem) =>
      //       cartItem.id === item.id ? {...itemExist, serving: item.serving} : cartItem
      //     )
      //   });
      // } else if(type==="sauce") {
      //   this.setState({cart:
      //     this.state.cart.map((cartItem) =>
      //       cartItem.id === item.id ? {...itemExist, sauces: item.sauces} : cartItem
      //     )
      //   });
      // }
    }
    this.updateCartTotal();
  }

  addToCart = (item) => {
    const itemExist = this.state.cart.find((cartItem) => cartItem.id === item.id);
    if(itemExist) {
      this.setState({cart:
        this.state.cart.map((cartItem) =>
          cartItem.id === item.id ? {...itemExist, quantity: itemExist.quantity + 1} : cartItem
        )
      });
    } else {
      this.setState({cart: [...this.state.cart, {...item, quantity: 1}]});
    }
    this.updateCartTotal();
  }

  removeFromCart = (item) => {
    const itemExist = this.state.cart.find((cartItem) => cartItem.id === item.id);
    if(itemExist.quantity === 1) {
      this.setState({cart:
          this.state.cart.filter((cartItem) => cartItem.id !== item.id
        )
      });
    } else {
      this.setState({cart: this.state.cart.map((cartItem) =>
          cartItem.id === item.id ? {...itemExist, quantity: itemExist.quantity - 1} : cartItem
        )
      });
    }
    this.updateCartTotal();
  }

  updateCartTotal = () => {
    setTimeout(()=>{
      this.setState({cartTotal: this.state.cart.reduce((price, cartItem) =>
          price + cartItem.price * cartItem.quantity, 0
        )
      });
      localStorage.setItem("cartFoodItems", JSON.stringify(this.state.cart));
      localStorage.setItem("cartTotalPrice", this.state.cartTotal);
    }, 10);    
  }

	login = () => {
	  this.setState({loggedIn: true});
	  localStorage.setItem("uloggedIn", true);
	  return <Redirect to="/home" />
	}

  logout = () => {
    axios({
      url: this.state.apiUrl+"logout",
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": localStorage.getItem("clogtoken")
      },
    })
    .then((res) => {
      if(res.data.status) {
        this.endLoginSession();
        // Update localStorage variables
        // localStorage.removeItem("cuser");
        // localStorage.removeItem("clogtoken");
        // localStorage.setItem("uloggedIn", false);
        // this.setState({loggedIn: false});
      } else {
        console.log(res.data.message);
      }
    })
    .catch((err) => {
      console.log(err.message);
      console.log("Api error! Please try later.");
    });
  }

  endLoginSession = () => {
    localStorage.removeItem("cuser");
    localStorage.removeItem("clogtoken");
    localStorage.setItem("uloggedIn", false);
    this.setState({loggedIn: false});
  }

  checkValidation = (elements) => {
    const retErr = [];
    for (let key in elements) {
      if(elements[key].classList && elements[key].classList.contains("required")) {
        if(elements[key].value === "" 
        || (elements[key].classList.contains("email") && !this.checkEmailValidation(elements[key].value))) { /* || (elements[key].classList.contains("phone") && !this.checkPhoneValidation(elements[key].value)) */ 
          elements[key].classList.add("is-invalid");
          retErr.push(false);
          continue;
        }
        elements[key].classList.remove("is-invalid");
        retErr.push(true);
      }
    }

    return retErr;
  }

  checkEmailValidation = (email) => {    
    let re = /^[^\s@]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return re.test(email);
  }

  checkPhoneValidation = (phone) => {    
    let re = /^\d{15}$/;
    return re.test(phone);
  }

  flashMsg = (type, response) => {
    let id = 'fm' + Date.now();

    let msg = document.createElement('div');
    msg.setAttribute('id', id);
    msg.setAttribute('class', 'flash-msg '+type);
    msg.textContent = response;

    document.body.prepend(msg);

    setTimeout(() => { document.querySelector("#"+id).classList.add("hide-flash-msg"); }, 3000);
    setTimeout(() => { document.querySelector("#"+id).remove(); }, 3500);
  }

  render() {
    const mediaUrl = this.state.apiUrl.replace("api/", "");

    document.getElementById('favicon').setAttribute('href', mediaUrl + 'images/theme/' + this.state.themeFavicon);
    document.getElementById('page_title').textContent = this.state.themeName;

    this.applyThemeColor(this.state.themeColor);

    return (
      <>
          {
            (this.props.location.pathname!=='/') ? <Header apiUrl={this.state.apiUrl} themeLogo={this.state.themeLogo} themeName={this.state.themeName} endSession={this.endLoginSession} loggedIn={this.state.loggedIn} logout={this.logout} curPickTime={this.state.curPickTime} cart={this.state.cart} cartTotal={parseFloat(this.state.cartTotal).toFixed(2)} settings={this.state.settings}/>:''
          }
          <Switch>
            <Route path="/" exact component={MessageBox} />
            <Route path="/home" exact>
              <Index apiUrl={this.state.apiUrl} themeName={this.state.themeName} flashMsg={this.flashMsg} pastTime={this.state.pastTime} pickTime={this.state.pickTime} curPickTime={this.state.curPickTime} setPickTime={this.setPickTime} loggedIn={this.state.loggedIn} cart={this.state.cart} addToCart={this.addToCart} removeFromCart={this.removeFromCart} cartTotal={parseFloat(this.state.cartTotal).toFixed(2)} settings={this.state.settings} shopOpen={this.state.shopOpen} updateItemSize={this.UpdateItemSize}/>
            </Route>
            <Route path="/contact-us" exact>
              <ContactUs settings={this.state.settings} themeName={this.state.themeName}/>
            </Route>
            <Route path="/my-account">
              <MyAccount apiUrl={this.state.apiUrl} flashMsg={this.flashMsg} loggedIn={this.state.loggedIn} logout={this.logout} checkValidation={this.checkValidation} settings={this.state.settings}/>
            </Route>
            <Route path="/register" exact>
              <Register apiUrl={this.state.apiUrl} themeName={this.state.themeName} settings={this.state.settings} flashMsg={this.flashMsg} checkValidation={this.checkValidation} />
            </Route>
            <Route path="/login" exact>
              <Login apiUrl={this.state.apiUrl} themeName={this.state.themeName} settings={this.state.settings} flashMsg={this.flashMsg} loggedIn={this.state.loggedIn} login={this.login} checkValidation={this.checkValidation}/>
            </Route>
            <Route path="/otp-verify" exact>
              <OTPVerify apiUrl={this.state.apiUrl} themeName={this.state.themeName} settings={this.state.settings} flashMsg={this.flashMsg} loggedIn={this.state.loggedIn} login={this.login} checkValidation={this.checkValidation}/>
            </Route>
            <Route path="/track-order/:id" exact>
              <TrackOrder apiUrl={this.state.apiUrl} flashMsg={this.flashMsg} getParams={this.getParams} curPickTime={this.state.curPickTime}/>
            </Route>
            <Route path="/invoice/:id" exact>
              <Invoice apiUrl={this.state.apiUrl} themeLogo={this.state.themeLogo} themeName={this.state.themeName} flashMsg={this.flashMsg} cart={this.state.cart} cartTotal={parseFloat(this.state.cartTotal).toFixed(2)} settings={this.state.settings} getParams={this.getParams}/>
            </Route>
            <Route path="/checkout" exact>
              <Checkout apiUrl={this.state.apiUrl} themeLogo={this.state.themeLogo} themeName={this.state.themeName} themeColor={this.state.themeColor} flashMsg={this.flashMsg}  pastTime={this.state.pastTime} pickTime={this.state.pickTime} curPickTime={this.state.curPickTime} setPickTime={this.setPickTime} cart={this.state.cart} addToCart={this.addToCart} removeFromCart={this.removeFromCart} cartTotal={parseFloat(this.state.cartTotal).toFixed(2)} settings={this.state.settings} shopOpen={this.state.shopOpen} checkValidation={this.checkValidation}/>
            </Route>
            <Route path="/privacy-policy" exact>
              <PrivacyPolicy themeName={this.state.themeName}/>
            </Route>
            <Route path="/cookies-policy" exact>
              <CookiesPolicy themeName={this.state.themeName}/>
            </Route>
            <Route path="/terms-conditions" exact>
              <TermsConditions themeName={this.state.themeName}/>
            </Route>
            <Route path="/listing" exact component={List} />
            <Route path="/extra" exact component={Extra} />
            <Route path="/thanks" exact component={Thanks} />
            <Route path="/detail" exact component={Detail} />
            <Route path="/404" exact component={NotFound} />
            <Route exact component={NotFound} />
          </Switch>
          {
            (this.props.location.pathname!=='/') ? <Footer themeName={this.state.themeName} settings={this.state.settings}/>:''
          }
      </>
    );
  }
}

export default App;
