import React from 'react';
import axios from "axios";
import {Link, Redirect} from 'react-router-dom';
import {Row,Col,Container,Form,Image} from 'react-bootstrap';

class OTPVerify extends React.Component {
    _isMounted = false;

	constructor(props, context) {
	    super(props, context);

	    this.state = {
      		errorText: "",
            otpExpireTimer: "00:00",
            expireTimestamp: 0
	    };
	}

    componentDidMount() {
        this._isMounted = true;
        this.otpExpireTime();
    }
    
    componentWillUnmount() {
        this._isMounted = false;
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state,callback)=>{
            return;
        };
    }

	updateErrorText = (error) => {
		this.setState({errorText: error});
		setTimeout(() => { this.setState({errorText: ""}); }, 5000);
	}

    //OTP expire
    otpExpireTime = () => {
        const formData = new FormData();
        const logCred = JSON.parse(localStorage.getItem("ulogCred"));

        for (let key in logCred) {
            formData.append(key, logCred[key]);
        }

        axios({
            url: this.props.apiUrl+"otp-expire-time",
            method: "POST",
            contentType: false,
            processType: false,
            headers: {
              "Accept": "application/json",
              "Content-Type": "application/json",
            }, 
            data: formData,
        })
        .then((res) => {
            if(res.data.status && this._isMounted) {
                this.setState({expireTimestamp: +res.data.expiry_time * 1000});
            } else {
                this.setState({expireTimestamp: 0});
                this.setState({otpExpireTimer: '00:00'});
                this.updateErrorText(res.data.message);
            }
        })
        .catch((err) => {
            this.updateErrorText("Api error! Please try later.");
        });
    }

    expiryTimerInterval = () => {
        let curTime = Date.now();
        let pendTime = this.state.expireTimestamp - curTime;
        
        if(pendTime > 0) {
            const distance = this.state.expireTimestamp - curTime;
            const mins = distance < 0 ? 0: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            const secs = distance < 0 ? 0: Math.floor((distance % (1000 * 60)) / 1000);

            let min = String(mins).padStart(2, '0');
            let sec = String(secs).padStart(2, '0');

            if(this._isMounted) {
                this.setState({otpExpireTimer: min+':'+sec});
            }
        } else if(this._isMounted) {
            this.setState({otpExpireTimer: '00:00'});
        }
    }

    //OTP login
	loginOTP = (e) => {
		e.preventDefault();
        const error = this.props.checkValidation(e.target.elements);
        const formData = new FormData(e.currentTarget);
        const logCred = JSON.parse(localStorage.getItem("ulogCred"));

        for (let key in logCred) {
            formData.append(key, logCred[key]);
        }

        if(error.indexOf(false) === -1) {
            axios({
                url: this.props.apiUrl+"check-otp",
                method: "POST",
                contentType: false,
                processType: false,
                headers: {
                  "Accept": "application/json",
                  "Content-Type": "application/json",
                }, 
                data: formData,
            })
            .then((res) => {
                if(res.data.status) {
                    if(res.data.user.profile_pic == null) {
                        res.data.user.profile_pic = "images/users/user.png";
                    }

                    localStorage.setItem("cuser", JSON.stringify(res.data.user));
                    localStorage.setItem("clogtoken", "Bearer "+res.data.token);
                    localStorage.removeItem("ulogCred");
                    this.props.login();
                } else {
                    this.updateErrorText(res.data.message);
                }
            })
            .catch((err) => {
                this.updateErrorText("Api error! Please try later.");
            });
        } else {
            this.updateErrorText("Please enter OTP");
        }
	}

    resendOTP = () => {
        const formData = new FormData();
        const logCred = JSON.parse(localStorage.getItem("ulogCred"));

        for (let key in logCred) {
            formData.append(key, logCred[key]);
        }

        axios({
            url: this.props.apiUrl+"send-otp",
            method: "POST",
            contentType: false,
            processType: false,
            headers: {
              "Accept": "application/json",
              "Content-Type": "application/json",
            }, 
            data: formData,
        })
        .then((res) => {
            this.otpExpireTime();
            this.updateErrorText(res.data.message);
        })
        .catch((err) => {
            this.updateErrorText("Api error! Please try later.");
        });
    }

	render() {
		if(this.props.loggedIn) {
			return <Redirect to="/home" />
		}

        if(this.props.settings.otp_login!==undefined && !this.props.settings.otp_login) {
            return <Redirect to="/login" />
        }

        if(this.state.expireTimestamp > 0) {
            const interval = setInterval(() => {
                this.expiryTimerInterval();
    
                if (this.state.expireTimestamp - new Date().getTime() < 0) {
                    clearInterval(interval);
                }
            }, 1000);
        }

    	return (
    	  <Container fluid className='bg-white'>
	         <Row>
	            <Col md={4} lg={6} className="d-none d-md-flex bg-image">
					<Image src="img/login-banner.jpg" alt="" />
				</Col>
	            <Col md={8} lg={6}>
	               <div className="d-flex align-items-center py-5">
	                  <Container>
	                     <Row>
	                        <Col md={9} lg={8} className="mx-auto pl-5 pr-5 login-box">
	                           <h3 className="login-heading mb-4">Verify OTP</h3>
            					<div id="errorText" className="d-flex text-danger mb-2">{this.state.errorText}</div>
							   <Form method="POST" id="OTPForm" name="OTPForm" onSubmit={this.loginOTP}>
	                              <div className="form-label-group">
	                                 <Form.Control type="text" id="otp" name="otp" placeholder="OTP" className="required"/>
	                                 <Form.Label htmlFor="otp">OTP</Form.Label>
                                     {this.state.otpExpireTimer!=="00:00"?<Link to="" className="resend-otp-link">{this.state.otpExpireTimer}</Link>:<Link to="#" className="resend-otp-link" onClick={(e) => {e.preventDefault(); this.resendOTP();}}>Resend OTP</Link>}
	                              </div>
								  <button type="submit" className="btn btn-lg btn-outline-primary btn-block btn-login text-uppercase font-weight-bold mb-2">Submit OTP</button>
	                           </Form>
	                        </Col>
	                     </Row>
	                  </Container>
	               </div>
	            </Col>
	         </Row>
	      </Container>
    	);
    }
}

export default OTPVerify;