import React from 'react';
import {Row,Col,Container} from 'react-bootstrap';

class MessageBox extends React.Component {
	render() {
    	return (
            <section className="section pt-5 pb-5">
                <Container>
                    <Row>
                        <Col id="message-box">
                            <h1>Are you interested in to see Demo Restaurant App?</h1>
                            <a href="https://www.kasaeshna.com/contact" className="btn btn-primary">Contact Us</a>
                        </Col>
                    </Row>
                </Container>
            </section>
    	);
    }
}


export default MessageBox;