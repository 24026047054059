import React from 'react';
import PropTypes from 'prop-types'; 
import {Link} from 'react-router-dom';
import {Row,Col,Container} from 'react-bootstrap';
import Icofont from 'react-icofont';

class Footer extends React.Component {
	days = [{short: "Mon", full: "day"}, {short: "Tue", full: "sday"}, {short: "Wed", full: "nesday"}, {short: "Thu", full: "rsday"}, {short: "Fri", full: "day"}, {short: "Sat", full: "urday"}, {short: "Sun", full: "day"}];

	render() {
		let year = new Date();

    	return (
    		<>
			    <section className="footer pt-5 pb-5">
					<Container>
						<Row>
							<Col md={4} sm={12}>
								<h6 className="mb-3">Opening Hours</h6>
								<table>
									<tbody>
										{this.props.settings.openingHours?this.props.settings.openingHours.map((day, index) => {
											const curDate = new Date();
											let curDay = curDate.getDay()===0?7:curDate.getDay();
											let keyIdx = index + 1;
											return (
												<tr className={this.days[curDay - 1].short === day.day? "active" : ""} key={keyIdx}>
													<th>{this.days[index].short+this.days[index].full}</th>
													<td>{day.open} - {day.close}</td>
												</tr>
										)}):(
											<tr>
												<th></th>
												<td></td>
											</tr>
										)}
									</tbody>
								</table>								
								<p></p>
							</Col>
							<Col md={1} sm={6} className="mobile-none">
							</Col>
							<Col md={3} sm={6} xs={12}>
								<h6 className="mb-3">Quick Links</h6>
								<ul>
									<li><Link to="/home">Home</Link></li>
									<li><Link to="/track-order/0">Track Order</Link></li>
									<li><Link to="/contact-us">Contact Us</Link></li>
									<li><Link to="/privacy-policy">Privacy Policy</Link></li>
									<li><Link to="/cookies-policy">Cookies Policy</Link></li>
									<li><Link to="/terms-conditions">Terms & Conditions</Link></li>
								</ul>
								<p></p>
							</Col>
							<Col md={3} sm={6} xs={12}>
								<h6 className="mb-3">Contact Details</h6>
								<ul>
									<li><a href="#"><Icofont icon='location-pin'/> {this.props.settings.address}</a></li>
									<li><a href="mailto:admin@kasaeshna.com"><Icofont icon='envelope'/> {this.props.settings.email}</a></li>
									<li><a href="tel:+910000000000"><Icofont icon='phone'/> {this.props.settings.country_code+this.props.settings.phone}</a></li>
								</ul>
							</Col>
						</Row>
					</Container>
			    </section>
				<footer className="pt-4 pb-4 text-center">
					<Container>
						<p className="mt-0 mb-0 text-center">{'© '+year.getFullYear()+' ' + this.props.themeName + '. All Rights Reserved'}</p>
						<small className="mt-0 mb-0 text-center"> Developed by <Link className="text-primary" target="_blank" to={`${this.props.firstLink}`}>{this.props.firstLinkText}</Link>
						</small>
					</Container>
				</footer>
		    </>
    	);
    }
}


Footer.propTypes = {
  sectionclassName: PropTypes.string,
  popularCHclassName:PropTypes.string,
  popularCountries: PropTypes.array,
  popularFHclassName:PropTypes.string,
  popularFood: PropTypes.array,
  copyrightText: PropTypes.string,
  madewithIconclassName: PropTypes.string,
  firstLinkText: PropTypes.string,
  firstLink: PropTypes.string,
};

let year = new Date();

Footer.defaultProps = {
    sectionclassName: 'footer-bottom-search pt-5 pb-5 bg-white',
	popularCHclassName:'text-black',
	popularCountries: [],
	popularFHclassName:'mt-4 text-black',
	popularFood: [],
	copyrightText: '© '+year.getFullYear()+' Restaurant App. All Rights Reserved',
	madewithIconclassName: 'heart heart-icon text-danger',
	firstLinkText: 'Kasa Eshna Private Limited',
	firstLink: "//kasaeshna.com",
}

export default Footer;