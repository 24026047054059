import React from 'react';
import axios from "axios";
import {Link} from 'react-router-dom';
import {Row,Col,Container,Form,Button} from 'react-bootstrap';
import Icofont from 'react-icofont';

class TrackOrder extends React.Component {
	_isMounted = false;

	constructor(props, context) {
	    super(props, context);

	    this.state = {
		  orderId: this.props.getParams(window.location.href, 1),
		  orderDetails: {id: this.props.getParams(window.location.href, 1), time: "", status: 1},
		  orderStatuses: [],
		  reload: false
	    };
	}

	componentDidMount() {
		this._isMounted = true;
		
		this.getOrderStatuses();
		this.getOrderTrack();

		const interval = setInterval(() => {
			this.getOrderTrack();
		}, 10000);
	  
		return () => {
			clearInterval(interval);
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state,callback)=>{
			return;
		};
	}

	getOrderStatuses = () => {
		axios({
			url: this.props.apiUrl+"order-statuses",
			method: "GET",
			headers: {
				"Accept": "application/json",
				"Content-Type": "application/json",
			}
		})
		.then((res) => {
			if(res.data.status) {
				if(this._isMounted) {
					this.setState({orderStatuses: res.data.orderStatuses});
				}
			} else {
				console.log(res.data.message);
			}
		})
		.catch((err) => {
			console.log(err.message);
			console.log("Api error! Please try later.");
		});
	}

	getOrderTrack = () => {
		if(this.state.orderId !== "0") {
			axios({
				url: this.props.apiUrl+"track-order/"+this.state.orderId,
				method: "GET",
				headers: {
					"Accept": "application/json",
					"Content-Type": "application/json",
				}
			})
			.then((res) => {
				if(res.data.status) {
					if(this._isMounted) {
						this.setState({orderDetails: res.data.order});
					}
				} else {
					console.log(res.data.message);
				}
			})
			.catch((err) => {
				console.log(err.message);
				console.log("Api error! Please try later.");
			});
		}
	}

	getTrackOrderDetais = () => {
		let orderId = document.querySelector("[name='order_id']").value;

		axios({
			url: this.props.apiUrl+"track-order/"+orderId,
			method: "GET",
			headers: {
				"Accept": "application/json",
				"Content-Type": "application/json",
			}
		})
		.then((res) => {
			if(res.data.status) {
				this.setState({orderId: orderId});
				this.setState({orderDetails: res.data.order});

				document.querySelector("#orderMessage").classList.remove("d-none");
				document.querySelector("#orderTracker").classList.remove("d-none");
				document.querySelector("[name='order_id']").value = "";
			} else {
				console.log(res.data.message);
			}
		})
		.catch((err) => {
			console.log(err.message);
			console.log("Api error! Please try later.");
		});
	}

	render() {
    	return (
    		<section className="section bg-white osahan-track-order-page position-relative">
	         <Container className="pt-5 pb-5">
	            <Row className="d-flex align-items-center justify-content-center">
	               <Col md={6}>
	                  <div className="bg-white p-4 shadow-lg mb-2">
						<Form>
							<Row>
								<Col sm={12}>
									<h5 className="mt-0 mb-3">Track your order</h5>
								</Col>
							</Row>								
							<Row>
								<Col md={8} sm={12}>
									<Form.Group>
										<Form.Control type="text" name="order_id" placeholder="Enter Order Id" /> 
									</Form.Group>
								</Col>
								<Col md={4} sm={12}>
									<Form.Group className="mb-0">
										<Button variant="primary" type="button" className="btn-block" onClick={this.getTrackOrderDetais}>Track Order</Button>
									</Form.Group>
								</Col>
							</Row>
						</Form>					  	
	                  </div>
	                  <div id="orderMessage" className={"bg-white p-4 shadow-lg"+(this.state.orderId>0?"":" d-none")}>
	                     <div className="osahan-track-order-detail po">
	                        <h5 className="mt-0 mb-3">Order #{this.state.orderId}</h5>
	                        <Row>
	                           <Col className="text-center">
	                              <h6 className="mb-0">Approximate pick up time <span id="estPickTime">{this.state.orderDetails.time}</span>. Restaurant chef will confirm the order shortly. You can check order status on this page.</h6>
	                           </Col>
	                        </Row>
	                     </div>
	                  </div>
	                  <div id="orderTracker" className={"bg-white p-5 shadow-lg mt-2"+(this.state.orderId>0?"":" d-none")}>
	                     <Row>
							<Col id="orderStatus" sm={12}>
								{this.state.orderStatuses.map((status) => (
									<div className={"order-status"+(+this.state.orderDetails.status>=status.id?" active":"")} key={status.id}>
										<Icofont icon="check"/> {status.name}
									</div>
								))}
							</Col>
							<Col sm={12} className="mt-4">
								<Link to={"/invoice/"+this.state.orderId} className="btn btn-primary">View Invoice</Link>
							</Col>
	                     </Row>
	                  </div>
	               </Col>
	            </Row>
	         </Container>
	      </section>
    	);
    }
}


export default TrackOrder;