import React from 'react';
import axios from "axios";
import {Redirect} from 'react-router-dom';
import {Row,Col,Container,Form,InputGroup,OverlayTrigger,Tooltip, Button} from 'react-bootstrap';
import Icofont from 'react-icofont';
import PageTitle from './common/PageTitle';
import CheckoutItem from './common/CheckoutItem';
import countryList from 'react-select-country-list';
import countryCodesList from './common/country-code.json';

class Checkout extends React.Component {
	constructor(props, context) {
	    super(props, context);

	    this.state = {
			countriesList: countryList().getData(),
			countryCodeList: countryCodesList,
			errorText: "",
			orderId: 0,
			userDetails: localStorage.getItem("cuser")?JSON.parse(localStorage.getItem("cuser")):{id: "", name: "", email: "", country_code: "", phone: "", address: "", address2: "", city: "", county: "", postcode: "", country: ""}
	    };
	}

    getQty = ({id,quantity}) => {
		//Get console data
    	//console.log(id);
    	//console.log(quantity);
	}

	updateErrorText = (error) => {
		this.setState({errorText: error});
		setTimeout(() => { this.setState({errorText: ""}); }, 5000);
	}

	removeValidation = (elements) => {
		for (let key in elements) {
		  if(elements[key].classList && elements[key].classList.contains("is-invalid")) {
			elements[key].classList.remove("is-invalid");
		  }
		}
	}

	placeOrder = (payment_id) => {
		const error = this.props.checkValidation(document.querySelector("#placeOrder").elements);
		const formData = new FormData(document.querySelector("#placeOrder"));
	
		if(error.indexOf(false) === -1) {
			this.removeValidation(document.querySelector("#placeOrder").elements);

			formData.append('cart', localStorage.getItem("cartFoodItems"));
			formData.append('subtotal', this.props.cartTotal);
			formData.append('grand_total', (parseFloat(this.props.cartTotal)*this.props.settings.vat + parseFloat(this.props.cartTotal)).toFixed(2));
			formData.append('time', this.props.curPickTime);
			formData.append('type', 'Pick-up');
			formData.append('note', document.querySelector("#note").value);
			formData.append('payment_id', payment_id);
			formData.append('payment_status', 0); //1

			axios({
				url: this.props.apiUrl+"save-order",
				method: "POST",
				contentType: false,
				processType: false,
				headers: {
					"Accept": "application/json",
					"Content-Type": "application/json",
				}, 
				data: formData,
			})
			.then((res) => {
				if(res.data.status) {
					localStorage.removeItem("cartFoodItems");
					localStorage.removeItem("cartTotalPrice");
					this.setState({orderId: res.data.id});
				} else {
					this.updateErrorText(res.data.message);
				}
			})
			.catch((err) => {
				console.log(err.message);
				this.updateErrorText("Api error! Please try later.");
			});
		} else {
			this.updateErrorText("Please enter billing details.");
		}
	}

	razorPayment = () => {
		let placeOrder = this.placeOrder;
		const mediaUrl = this.props.apiUrl.replace("api/", "");
		let ttlAmount = (parseFloat(this.props.cartTotal)*this.props.settings.vat + parseFloat(this.props.cartTotal)).toFixed(2);

		placeOrder('Cash on Delivery');
		/*let options = {
		  "key": process.env.REACT_APP_RAZOR_ID,
		  "amount": (ttlAmount * 100), // 2000 paise = INR 20, amount in paisa
		  "name": this.props.themeName,
		  "description": "Pickup Order "+localStorage.getItem("cartFoodItems"),
		  "image": mediaUrl + 'images/theme/' + this.props.themeLogo,
		  "handler": function (response){
			if(response.razorpay_payment_id) {
				placeOrder(response.razorpay_payment_id);
			} else {
				this.props.flashMsg("fail", "Payment cancelled");
			}
		  },
		  "prefill": {
			"name": this.state.userDetails.name,
			"email": this.state.userDetails.email
		  },
		  "notes": {
			"address": this.state.userDetails.address
		  },
		  "theme": {
			"color": this.props.themeColor
		  }
		};
	
		let rzp = new window.Razorpay(options);
		rzp.open();*/
	}

	selectDialCode = (dialCode) => {
		document.querySelector("#dial_code").textContent = dialCode;
	}

	getCountry = (countryCode) => {
		if(this.state.countriesList) {
			const countryCodeIndex = this.state.countriesList.findIndex((country, index) => country.value === countryCode);
			return countryCodeIndex!==-1?this.state.countriesList[countryCodeIndex].label:"";
		} else {
			return "";
		}
	}

	render() {
		if(this.state.orderId !== 0) {
			window.location.href="/track-order/"+this.state.orderId;
			//return <Redirect to={"/track-order/"+this.state.orderId} />;
		}

    	return (
			<>			
			<PageTitle 
				title="Checkout"
				subTitle=""
			/>
    		<section className="offer-dedicated-body mt-4 mb-4 pt-2 pb-2">
	         <Container>
	            <Row className={this.props.shopOpen?"":"shop-close"}>
	               <Col md={8}>
				   		<div id="book-a-table" className="bg-white rounded shadow-sm p-4 mb-5 rating-review-select-page">
							<h5 className="text-primary mb-4">Billing Details</h5>
            				<div id="errorText" className="d-flex text-danger mb-2">{this.state.errorText}</div>
							<Form method="POST" id="placeOrder" name="placeOrder" onSubmit={this.placeOrder}>
								<Row>
									<Col sm={6}>
										<Form.Group>
											<Form.Label>First Name</Form.Label>
											<Form.Control type="text" name="first_name" placeholder="Enter First Name" className="required" defaultValue={this.state.userDetails.name.split(" ")[0]}/> 
										</Form.Group>
									</Col>
									<Col sm={6}>
										<Form.Group>
											<Form.Label>Last Name</Form.Label>
											<Form.Control type="text" name="last_name" placeholder="Enter Last Name" className="required" defaultValue={this.state.userDetails.name.split(" ")[1]}/> 
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={6}>
										<Form.Group>
											<Form.Label>Email Address</Form.Label>
											<Form.Control type="email" name="email" placeholder="Enter Email address" className="required email" defaultValue={this.state.userDetails.email}/>
										</Form.Group>
									</Col>
									<Col sm={6}>
										<Form.Group className="mobile-field">
											<Form.Label>Mobile number</Form.Label>
											<span><span id="dial_code">{this.state.userDetails.country_code!==""?this.state.userDetails.country_code:this.props.settings.country_code}</span> <Icofont icon="rounded-down"/></span>
											<Form.Control as="select" id="country_code" name="country_code" defaultValue={this.state.userDetails.country_code!==""?this.state.userDetails.country_code:this.props.settings.country_code} onChange={(e) => {this.selectDialCode(e.target.value)}}>
												<option value="">Select Dial Code</option>
												{this.state.countryCodeList.map((code, index) => {
													let keyIdx = index + 1;
													return (<option value={code.dial_code} key={keyIdx} selected={code.dial_code===(this.state.userDetails.country_code!==""?this.state.userDetails.country_code:this.props.settings.country_code)}>{code.name+" ("+code.dial_code+")"}</option>);
												})}
											</Form.Control>
											<Form.Control type="number" id="phone" name="phone" placeholder="Enter Mobile number" className="required phone" defaultValue={this.state.userDetails.phone}/>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={12}>
										<Form.Group>
											<Form.Label>Address Line 1</Form.Label>
											<Form.Control type="text" name="address" placeholder="Address Line 1" className="required" defaultValue={this.state.userDetails.address}/>
										</Form.Group>
									</Col>
									<Col sm={12}>
										<Form.Group>
											<Form.Label>Address Line 2</Form.Label>
											<Form.Control type="text" name="address2" placeholder="Address Line 2" defaultValue={this.state.userDetails.address2}/>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={6}>
										<Form.Group>
											<Form.Label>City / Town</Form.Label>
											<Form.Control type="text" name="city" placeholder="Enter City" className="required" defaultValue={this.state.userDetails.city}/>
										</Form.Group>
									</Col>
									<Col sm={6}>
										<Form.Group>
											<Form.Label>County / Province / State</Form.Label>
											<Form.Control type="text" name="county" placeholder="Enter County" className="required" defaultValue={this.state.userDetails.county}/>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={6}>
										<Form.Group>
											<Form.Label>Postcode</Form.Label>
											<Form.Control type="text" name="postcode" placeholder="Enter Postcode" className="required" defaultValue={this.state.userDetails.postcode}/>
										</Form.Group>
									</Col>
									<Col sm={6}>
										<Form.Group>
											<Form.Label>Country</Form.Label>
											<Form.Control as="select" name="country" defaultValue={this.getCountry(this.props.settings.country)} className="required" readOnly>
												<option value="">Select Country</option>
												{this.state.countriesList.map((country, index) => {
													let keyIdx = index + 1;
													return (<option value={country.label} key={keyIdx} selected={this.props.settings.country===country.value}>{country.label}</option>);
												})}
											</Form.Control>
										</Form.Group>
									</Col>
								</Row>
								{/* <Form.Group className="text-right">
									<Button variant="primary" type="button"> Submit </Button>
								</Form.Group> */}
							</Form>
						</div>
	               </Col>
	               <Col md={4}>
	               	<div className="generator-bg rounded shadow-sm mb-4 p-4 osahan-cart-item">
						<h5 className="mb-1 text-primary">Schedule Pick-up</h5>
						<div className="pt-2"></div>
						<Form.Group className="pick_timer mb-1">
							<Icofont icon='clock-time'/>
							<Form.Control as="select" name="pick_time" disabled={this.props.curPickTime==="Closed"} onChange={(e)=> {this.props.setPickTime(e.target.value);}}>
								{this.props.pickTime.map((time, index) => {
									let keyIdx = index + 1;
									if(this.props.pastTime > index)
										return null; 
										//Disable select dropdown option
										//(<option value={time} key={keyIdx} disabled>{time}</option>);
									else
										return (<option value={time} key={keyIdx} selected={this.props.curPickTime===time}>{time}</option>);
								})}
							</Form.Control>
						</Form.Group>
						<p className="text-danger mb-0">Please select pick up time.</p>
						<div className="pt-3"></div>
						<h5 className="mb-1 text-primary">Your Order</h5>
						<div className="pt-2"></div>
						<div className="bg-white rounded mb-3"> {/*shadow-sm*/}
							{this.props.cart.map((item, index) => (
								<CheckoutItem 
									key={item.id}
									id={item.id}
									itemName={item.title}
									price={item.price}
									size={item.size}
									priceUnit={this.props.settings.currency}
									qty={item.quantity}
									show={true}
									getValue={this.getQty}
									cart={this.props.cart} 
									addToCart={this.props.addToCart} 
									removeFromCart={this.props.removeFromCart}
								/>
							))}
						</div>
						<div className="mb-2 bg-white rounded clearfix">
							<InputGroup className="mb-0">
							<InputGroup.Prepend>
								<InputGroup.Text><Icofont icon="comment" /></InputGroup.Text>
							</InputGroup.Prepend>
							<Form.Control as="textarea" id="note" name="note" placeholder="Any suggestions? We will pass it on..." aria-label="With textarea" />
							</InputGroup>
						</div>
						<div className="mb-2 bg-white rounded clearfix">
							<p className="mb-1">Subtotal <span className="float-right text-dark">{this.props.settings.currency+this.props.cartTotal}</span></p>
							<p className="mb-1">Tax <span style={{fontSize: "12px"}}>({this.props.settings.taxDesc})</span> ({this.props.settings.vat*100}%)
								<OverlayTrigger
								key="top"
								placement="top"
								overlay={
									<Tooltip id="tooltip-top">
									{this.props.settings.taxDesc}
									</Tooltip>
								}
								>
								<span className="text-primary ml-1">
									<Icofont icon="info-circle" />
								</span> 
								</OverlayTrigger>
							<span className="float-right text-dark">{this.props.settings.currency+((parseFloat(this.props.cartTotal)*this.props.settings.vat).toFixed(2))}</span>                           
							</p>
							<hr />
							<h6 className="mb-0">Total <span className="float-right">{this.props.settings.currency+((parseFloat(this.props.cartTotal)*this.props.settings.vat + parseFloat(this.props.cartTotal)).toFixed(2))}</span></h6>
							<div className="pt-2"></div>
						</div>						
						<div className="pt-1"></div>
						<h5 className="mb-1 text-primary">Payment</h5>
						<div className="pt-2"></div>
						<label><input type="radio" name="pay_method" checked/> Cash on Delivery</label><br/>
						{/*<label disabled><input type="radio" name="pay_method" disabled/> Razor Pay</label>*/}
						<div className="pt-2"></div>
						<Button className={"btn btn-primary btn-block btn-lg"+((this.props.curPickTime!=="Closed" && this.props.cart.length>0)?"":" btn-disabled")} onClick={this.razorPayment}>Place Order</Button>
	   				</div>
	               </Col>
	            </Row>
	         </Container>
			</section>
			</>	
    	);
    }
}

export default Checkout;
