import React from 'react';
import axios from "axios";
import {Switch,Route,NavLink} from 'react-router-dom';
import {Row,Col,Container,Image} from 'react-bootstrap';
import Profile from './myaccount/Profile';
import Orders from './myaccount/Orders';
import EditProfileModal from './modals/EditProfileModal';

class MyAccount extends React.Component {
   constructor(props, context) {
      super(props, context);

      this.state = {
         showEditProfile: false,
         mediaUrl: this.props.apiUrl.replace("api/", ""),
         userDetails: localStorage.getItem("cuser")!=null?JSON.parse(localStorage.getItem("cuser")):{id: "", profile_pic: "images/users/user.png", name: "", email: "", phone: "", address: "", address2: "", city: "", county: "", postcode: "", country: ""}
      };
   }

   hideEditProfile = () => this.setState({ showEditProfile: false });

   changeProfilePic = (profilePic) => {
      const formData = new FormData();
      formData.append("profile_pic", profilePic);

      if(profilePic !== null) {
         axios({
             url: this.props.apiUrl+"update-profile-pic/"+this.state.userDetails.id,
             method: "POST",
             headers: {
                 "Accept": "application/json",
                 "Content-Type": "multipart/form-data",
                 "Authorization": localStorage.getItem("clogtoken")
             },
             data: formData
         })
         .then((res) => {
            if(res.data.status) {
               const userDet = JSON.parse(localStorage.getItem("cuser"));
               userDet.profile_pic = res.data.profile_pic!=null?res.data.profile_pic:"images/users/user.png";

               document.querySelector("#profilePhoto").src = this.state.mediaUrl+userDet.profile_pic;

					localStorage.setItem("cuser", JSON.stringify(userDet));

               this.props.flashMsg("success", res.data.message);
            } else {
               this.props.flashMsg("fail", res.data.message);
            }
         })
         .catch((err) => {
            console.log(err.message);
            this.props.flashMsg("fail", "Api error! Please try later.");
         });
      }
   }

	render() {

    	return (
    		<>
        <EditProfileModal show={this.state.showEditProfile} onHide={this.hideEditProfile}/>
        <section className="section pt-4 pb-4 osahan-account-page">
           <Container>
              <Row>
                 <Col md={3}>
                    <div className="osahan-account-page-left shadow-sm bg-white h-100">
                       <div className="border-bottom p-4">
                          <div className="osahan-user text-center">
                             <div className="osahan-user-media">
                                <Image className="mb-3 rounded-pill shadow-sm mt-1" id="profilePhoto" src={this.state.mediaUrl+this.state.userDetails.profile_pic} alt={this.state.userDetails.name} />
                                <div className="upload-profile-pic-form">
                                    <label htmlFor="upldProfilePic">Change Photo</label>
                                    <input type="file" id="upldProfilePic" onChange={(e) => this.changeProfilePic(e.target.files[0])}></input>
                                </div>
                                <div className="osahan-user-media-body">
                                   <h6 className="mb-2">{this.state.userDetails.name}</h6>
                                   <p className="mb-1 text-center">{this.state.userDetails.country_code+" "+this.state.userDetails.phone}</p>
                                   <p className="text-center content-wrap">{this.state.userDetails.email}</p>
                                </div>
                             </div>
                          </div>
                       </div>
                       <ul className="nav flex-column border-0 pt-4 pb-4">
                          <li className="nav-item">
                             <NavLink className="nav-link" activeClassName="active" exact to="/my-account/profile"><i className="icofont-user"></i> Profile</NavLink>
                          </li>
                          <li className="nav-item">
                             <NavLink className="nav-link" activeClassName="active" exact to="/my-account/orders"><i className="icofont-food-cart"></i> Orders</NavLink>
                          </li>
                          <li className="nav-item">
                             <NavLink className="nav-link" activeClassName="active" exact to="/login" onClick={(e)=>{e.preventDefault(); this.props.logout();}}><i className="icofont-power"></i> Logout</NavLink>
                          </li>
                       </ul>
                    </div>
                 </Col>
                 <Col md={9}>
                  <Switch>
                    <Route path="/my-account/profile" exact>
                        <Profile apiUrl={this.props.apiUrl} flashMsg={this.props.flashMsg} loggedIn={this.props.loggedIn} checkValidation={this.props.checkValidation} settings={this.props.settings}/>
                    </Route>
                    <Route path="/my-account/orders" exact>
                        <Orders apiUrl={this.props.apiUrl} flashMsg={this.props.flashMsg} loggedIn={this.props.loggedIn} checkValidation={this.props.checkValidation} settings={this.props.settings}/>
                    </Route>
                  </Switch>
                 </Col>
              </Row>
           </Container>
        </section>
    		</>
    	);
   }
}

export default MyAccount;