import React from 'react';
import axios from "axios";
import {Link, Redirect} from 'react-router-dom';
import {Row,Col,Container,Form,Image} from 'react-bootstrap';

class Login extends React.Component {
	constructor(props, context) {
	    super(props, context);

	    this.state = {
      		errorText: "",
			otpPage: false
	    };
	}

	updateErrorText = (error) => {
		this.setState({errorText: error});
		setTimeout(() => { this.setState({errorText: ""}); }, 5000);
	}

	loginAccount = (e) => {
		e.preventDefault();
		const error = this.props.checkValidation(e.target.elements);
		const formData = new FormData(e.currentTarget);
	
		if(error.indexOf(false) === -1) {
			axios({
				url: this.props.apiUrl+"login",
				method: "POST",
				contentType: false,
				processType: false,
				headers: {
					"Accept": "application/json",
					"Content-Type": "application/json",
				}, 
				data: formData,
			})
			.then((res) => {
				if(res.data.status) {
					if((+res.data.user) === 4) {
						if(this.props.settings.otp_login) {
							let logCred = {
								email: formData.get("email"),
								password: formData.get("password")
							};
							localStorage.setItem("ulogCred", JSON.stringify(logCred));
							this.updateErrorText("");
							this.setState({otpPage: true});
							return true;
						}

						if(res.data.userDet.profile_pic == null) {
							res.data.userDet.profile_pic = "images/users/user.png";
						}

						localStorage.setItem("cuser", JSON.stringify(res.data.userDet));
						localStorage.setItem("clogtoken", "Bearer "+res.data.token);
						this.props.login();
						return true;
					}

					this.updateErrorText("Invalid credentials");
					return false;
				}
				
				this.updateErrorText(res.data.message);
				return false;
			})
			.catch((err) => {
				console.log(err.message);
				this.updateErrorText("Api error! Please try later.");
			});

			return true;
		}

		this.updateErrorText("Please enter credentials");
		return false;
	}

	render() {
		if(this.props.loggedIn) {
			return <Redirect to="/home" />
		}

		if(this.state.otpPage) {
			return <Redirect to="/otp-verify" />
		}

    	return (
    	  <Container fluid className='bg-white'>
	         <Row>
	            <Col md={4} lg={6} className="d-none d-md-flex bg-image">
					<Image src="img/login-banner.jpg" alt="" />
				</Col>
	            <Col md={8} lg={6}>
	               <div className="d-flex align-items-center py-5">
	                  <Container>
	                     <Row>
	                        <Col md={9} lg={8} className="mx-auto pl-5 pr-5 login-box">
	                           <h3 className="login-heading mb-4">Login</h3>
            					<div id="errorText" className="d-flex text-danger mb-2">{this.state.errorText}</div>
	                           <Form method="POST" id="loginForm" name="loginForm" onSubmit={this.loginAccount}>
	                              <div className="form-label-group">
	                                 <Form.Control type="email" name="email" id="email" placeholder="Email address" className="required email"/>
	                                 <Form.Label htmlFor="email">Email address / Mobile</Form.Label>
	                              </div>
	                              <div className="form-label-group">
	                                 <Form.Control type="password" name="password" id="password" placeholder="Password" className="required"/>
	                                 <Form.Label htmlFor="password">Password</Form.Label>
	                              </div>
	                              <Form.Check  
	                              	className='mb-3'
							        custom
							        type="checkbox"
							        id="custom-checkbox"
							        label="Remember password"
							      />
	                              <button type="submit" className="btn btn-lg btn-outline-primary btn-block btn-login text-uppercase font-weight-bold mb-2">Sign In</button>
	                              <div className="text-center pt-3">
	                                 Don’t have an account? <Link className="font-weight-bold" to="/register">Sign Up</Link>
	                              </div>
		                           {/* <hr className="my-4" />
		                           <p className="text-center">LOGIN WITH</p>
		                           <div className="row">
		                              <div className="col pr-2">
		                                 <Button type="submit" className="btn pl-1 pr-1 btn-lg btn-google font-weight-normal text-white btn-block text-uppercase"><FontAwesome icon="google" className="mr-2" /> Google</Button>
		                              </div>
		                              <div className="col pl-2">
		                                 <Button type="submit" className="btn pl-1 pr-1 btn-lg btn-facebook font-weight-normal text-white btn-block text-uppercase"><FontAwesome icon="facebook" className="mr-2" /> Facebook</Button>
		                              </div>
		                           </div> */}
	                           </Form>
	                        </Col>
	                     </Row>
	                  </Container>
	               </div>
	            </Col>
	         </Row>
	      </Container>
    	);
    }
}


export default Login;