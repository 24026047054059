import React from 'react';
import {Link} from 'react-router-dom';
import {Form,Image,Badge,Button} from 'react-bootstrap';
import PropTypes from 'prop-types'; 
import Icofont from 'react-icofont';
import SidesExtrasModal from '../modals/SidesExtrasModal';

class BestSeller extends React.Component {
  constructor(props) {
    super(props);

    let serveItem = (document.querySelector("input[name=serving"+props.id+"]")?document.querySelector("input[name=serving"+props.id+"]:checked").value:"");
    let itemSize = (props.price.small?"small":"medium");

    this.state = {
      quantity: props.qty || 0,
      serve: props.itemServe?props.itemServe:serveItem,
      sauce: props.itemSauce.length?props.itemSauce:[],
      size: props.price.length>1?itemSize:"",
      price: props.size!==""?props.price[props.size]:props.price["small"],
      show: props.show || true,
      max: props.maxValue || 100,
      min: props.minValue || 0,
      showSideExtraModal: false
    };
  }

  UpdateSize = (id, size) => {
    this.setState({ size: size });
    this.setState({ price: parseFloat(this.props.price[size]) });
    this.props.updateItemSize({id: this.props.id, size: size, price: this.props.price[size]}, "size");
    document.querySelector(".itemPrice"+id).textContent = this.props.priceUnit+this.props.price[size];
  }

  IncrementItem = (size) => {
    if(this.props.serving.length>0 || this.props.sauces.length>0) {
      this.setState({ showSideExtraModal: true });
    }    
    const serve = document.querySelector("input[name=serving"+this.props.id+"]")?(document.querySelector("input[name=serving"+this.props.id+"]:checked").value):"";
    const sauce = [];

    if(document.querySelector(".sauces"+this.props.id)) {
      document.querySelectorAll(".sauces"+this.props.id).forEach((option) => {
        if(option.checked)
          sauce.push(option.value);
      });
    }

    if(this.state.quantity >= this.state.max) {
      //Positive Code
    }else {
      const price = size!==""?this.props.price[size]:this.props.price["small"];
      this.setState({ serve: serve });
      this.setState({ sauce: sauce });
      this.setState({ quantity: this.state.quantity + 1 });
      this.setState({ size: size });
      this.setState({ price: price });
      this.props.getValue({id: this.props.id, quantity: (this.state.quantity + 1 )});
      this.props.addToCart({id: this.props.id, title: this.props.title, size: size, price: size!==""?this.props.price[size]:this.props.price["small"], quantity: this.props.quantity, serving: serve, sauces: sauce});
    }

    if(document.querySelector(".cartItem"+this.props.id+" button.inc")) {
      document.querySelector(".cartItem"+this.props.id+" button.inc").click();
    }
  }

  DecreaseItem = () => {
    const serve = document.querySelector("input[name=serving"+this.props.id+"]")?(document.querySelector("input[name=serving"+this.props.id+"]:checked").value):"";
    const sauce = [];

    if(document.querySelector(".sauces"+this.props.id)) {
      document.querySelectorAll(".sauces"+this.props.id).forEach((option) => {
        if(option.checked)
          sauce.push(option.value);
      });
    }

    if(this.state.quantity <= (this.state.min)) {
      //Positive Code
    }else {
      this.setState({ serve: serve });
      this.setState({ sauce: sauce });
      this.setState({ quantity: this.state.quantity - 1 });
      this.props.getValue({id: this.props.id, quantity: (this.state.quantity - 1 )});
      this.props.removeFromCart({id: this.props.id, title: this.props.title, size: this.props.size, price: this.props.size!==""?this.props.price[this.props.size]:this.props.price["small"], quantity: this.props.quantity, serving: serve, sauces: sauce});
    }

    if(document.querySelector(".cartItem"+this.props.id+" button.dec")) {
      document.querySelector(".cartItem"+this.props.id+" button.dec").click();
    }
  }

  addServing = (serve) => {
    this.setState({ serve: serve });
    this.props.updateItemSize({id: this.props.id, serving: serve}, "serve");
  }

  addSauces = (sauce) => {
    this.setState({ sauce: sauce });
    this.props.updateItemSize({id: this.props.id, sauces: sauce}, "sauce");
  }

  hideSideExtraModal = () => this.setState({ showSideExtraModal: false });

  showSEModal = () => {
    return (this.props.serving.length>0 || this.props.sauces.length>0)?<SidesExtrasModal show={this.state.showSideExtraModal} onHide={this.hideSideExtraModal} addServing={this.addServing} addSauces={this.addSauces} itemProps={this.props} state={this.state}/>:null;
  }

  showSubTitle = () => {
    return this.props.subTitle ? (<p className="text-gray mb-1">{this.props.subTitle}</p>):null;
  }

  showPriceBySize = () => {
    return (this.props.price.small?+this.props.price.small:+this.props.price.medium).toFixed(2);
  }

  showItemForm = () => {
    let defaultSize = (this.props.price.small?"small":"medium");
    return (Object.keys(this.props.price).length > 1)? (<Form.Control as="select" className={"size"+this.props.id} defaultValue={this.props.size?this.props.size:defaultSize} onChange={(e) => {this.UpdateSize(this.props.id, e.target.value)}}>
    {Object.keys(this.props.price).map((size, index)=>{
      let keyIdx = index + 1;
      return ( <option value={size} key={keyIdx}>{size}</option> )
    })}
    </Form.Control>):null;
  }

  render() {
    return (
      <div className={"list-card bg-white rounded overflow-hidden position-relative shadow-sm foodItem"+this.props.id}> {/* h-100*/}
        {this.showSEModal()}
        <div className="list-card-image">
          {/* <div className={`favourite-heart position-absolute ${this.props.favIcoIconColor}`}>
            <Link to="#">
              <Icofont icon='heart'/>
            </Link>
          </div> */}
          <Link to="#">
            <Image src={this.props.image} className={this.props.imageClass} alt={this.props.imageAlt} />
          </Link>
        </div>
        <div className="p-3 position-relative">
          <div className="list-card-body">
            <h6 className="mb-1"><Link to="#" className="text-black">{this.props.title}</Link></h6>
            {this.showSubTitle()}

            {(this.props.price)? (
                <p className="text-gray time mb-0">
                  <Link className={"btn btn-link btn-sm pl-0 text-black pr-0 itemPrice"+this.props.id} to="#">{this.props.priceUnit}{this.showPriceBySize()}</Link>

                  {this.showItemForm()}

                  {(this.props.isNew)? (<Badge variant="success" className='ml-1'>NEW</Badge>):"" }
                    
                  {this.state.quantity===0?
                    <span className="float-right"> 
                      <Button variant='outline-secondary' onClick={() => this.IncrementItem(document.querySelector(".size"+this.props.id)?document.querySelector(".size"+this.props.id).value:"")} size="sm">ADD</Button>
                    </span>
                    :
                    <span className="count-number float-right">
                        <Button variant="outline-secondary" onClick={this.DecreaseItem} className="btn-sm left dec"> <Icofont icon="minus" /> </Button>
                        <input className="count-number-input" type="text" value={this.state.quantity} readOnly/>
                        <Button variant="outline-secondary" onClick={() => this.IncrementItem(document.querySelector(".size"+this.props.id)?document.querySelector(".size"+this.props.id).value:"")} className="btn-sm right inc"> <Icofont icon="icofont-plus" /> </Button>
                    </span>
                  }
                </p>
              ):''
            }
          </div>
        </div>
      </div>
    );
  }
}


BestSeller.propTypes = {
  title: PropTypes.string.isRequired,
  imageAlt: PropTypes.string,
  image: PropTypes.string.isRequired,
  imageClass: PropTypes.string,
  isNew: PropTypes.bool,
  subTitle: PropTypes.string,
  //price: PropTypes.number.isRequired,
  priceUnit: PropTypes.string.isRequired,
  favIcoIconColor: PropTypes.string,
  id: PropTypes.number.isRequired,
  qty: PropTypes.number,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  getValue: PropTypes.func.isRequired
};
BestSeller.defaultProps = {
  imageAlt:'',
  imageClass:'',
  subTitle: '',
  isNew: false,
  //price: '',
  priceUnit:'',
  favIcoIconColor: 'text-danger',
}

export default BestSeller;