import React from 'react';
import axios from "axios";
import {Redirect} from 'react-router-dom';
import {Row,Col,Form,Button} from 'react-bootstrap';
import Icofont from 'react-icofont';
import countryList from 'react-select-country-list';
import countryCodesList from '.././common/country-code.json';

class Profile extends React.Component {
	constructor(props, context) {
	    super(props, context);

	    this.state = {
			countriesList: countryList().getData(),
			countryCodeList: countryCodesList,
			errorText: "",
			formName: "",
			userDetails: localStorage.getItem("cuser")?JSON.parse(localStorage.getItem("cuser")):{id: "", profile_pic: "images/users/user.png", name: "", email: "", country_code: "", phone: "", address: "", address2: "", city: "", county: "", postcode: "", country: ""}
	    };
	}

	updateErrorText = (error) => {
		this.setState({errorText: error});
		setTimeout(() => { this.setState({errorText: ""}); }, 5000);
	}

	editProfile = (e) => {
		e.preventDefault();
		const error = this.props.checkValidation(e.target.elements);
		const formData = new FormData(e.currentTarget);
		this.setState({formName: "profileForm"});
	
		if(error.indexOf(false) === -1) {
			axios({
				url: this.props.apiUrl+"update-profile/"+this.state.userDetails.id,
				method: "POST",
				contentType: false,
				processType: false,
				headers: {
					"Accept": "application/json",
					"Content-Type": "application/json",
					"Authorization": localStorage.getItem("clogtoken")
				}, 
				data: formData,
			})
			.then((res) => {
				if(res.data.status) {
					const userDet = JSON.parse(localStorage.getItem("cuser"));
					userDet.name = formData.get("first_name") +' '+ formData.get("last_name");
					userDet.email = formData.get("email");
					userDet.country_code = formData.get("country_code");
					userDet.phone = formData.get("phone");
					userDet.address = formData.get("address");
					userDet.address2 = formData.get("address2");
					userDet.city = formData.get("city");
					userDet.county = formData.get("county");
					userDet.postcode = formData.get("postcode");
					userDet.country = formData.get("country");
					localStorage.setItem("cuser", JSON.stringify(userDet));

					this.props.flashMsg("success", res.data.message);
				} else {
					this.updateErrorText(res.data.message);
				}
			})
			.catch((err) => {
				console.log(err.message);
				this.updateErrorText("Api error! Please try later.");
			});
		} else {
			this.updateErrorText("Please enter profile details.");
		}
	}

    changePwd = (e) => {        
        e.preventDefault();
        const error = this.props.checkValidation(e.target.elements);
        const formData = new FormData(e.currentTarget);
        formData.append("user_id", this.state.userDetails.id);
		this.setState({formName: "passwordForm"});

        if(error.indexOf(false) === -1) {
            const pwd1 = document.querySelector("#password").value;
            const pwd2 = document.querySelector("#confirm_password").value;

            if(pwd1===pwd2) {
                axios({
                    url: this.props.apiUrl+"change-password",
                    method: "POST",
                    contentType: false,
                    processType: false,
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        "Authorization": localStorage.getItem("clogtoken")
                    },
                    data: formData,
                })
                .then((res) => {
                    if(res.data.status) {
                        this.props.flashMsg("success", res.data.message);
                    } else {
                        this.updateErrorText(res.data.message);
                    }
                })
                .catch((err) => {
                    console.log(err.message);
                    this.updateErrorText("Api error! Please try later.");
                });
            } else {
                this.updateErrorText("Password does not match.");
            }
        } else {
            this.updateErrorText("Please enter new password.");
        }
    }

	selectDialCode = (dialCode) => {
		document.querySelector("#dial_code").textContent = dialCode;
	}

	getCountry = (countryCode) => {
		if(this.state.countriesList) {
			const countryCodeIndex = this.state.countriesList.findIndex((country, index) => country.value === countryCode);
			return countryCodeIndex!==-1?this.state.countriesList[countryCodeIndex].label:"";
		} else {
			return "";
		}
	}

	render() {
		if(!this.props.loggedIn) {
			return <Redirect to="/login" />
		}

    	return (
    		<>
    		    <Row>
					<Col md={12}>
						<div id="profile-form" className="bg-white rounded shadow-sm p-4 mb-4">
							<h5 className="text-primary mb-4">My Profile</h5>
            				<div id="errorText" className="d-flex text-danger mb-2">{this.state.formName==="profileForm"?this.state.errorText:""}</div>
							<Form method="POST" id="editProfile" name="editProfile" onSubmit={this.editProfile}>
								<Row>
									<Col sm={6}>
										<Form.Group>
											<Form.Label>First Name</Form.Label>
											<Form.Control type="text" name="first_name" placeholder="Enter First Name" className="required" defaultValue={this.state.userDetails.name.split(" ")[0]}/> 
										</Form.Group>
									</Col>
									<Col sm={6}>
										<Form.Group>
											<Form.Label>Last Name</Form.Label>
											<Form.Control type="text" name="last_name" placeholder="Enter Last Name" className="required" defaultValue={this.state.userDetails.name.split(" ")[1]}/> 
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={6}>
										<Form.Group>
											<Form.Label>Email Address</Form.Label>
											<Form.Control type="email" name="email" placeholder="Enter Email address" className="required email" defaultValue={this.state.userDetails.email}/>
										</Form.Group>
									</Col>
									<Col sm={6}>
										<Form.Group className="mobile-field">
											<Form.Label>Mobile number</Form.Label>
											<span><span id="dial_code">{this.state.userDetails.country_code!==""?this.state.userDetails.country_code:this.props.settings.country_code}</span> <Icofont icon="rounded-down"/></span>
											<Form.Control as="select" id="country_code" name="country_code" defaultValue={this.state.userDetails.country_code!==""?this.state.userDetails.country_code:this.props.settings.country_code} onChange={(e) => {this.selectDialCode(e.target.value)}}>
												<option value="">Select Dial Code</option>
												{this.state.countryCodeList.map((code, index) => {
													let keyIdx = index + 1;
													return (<option value={code.dial_code} key={keyIdx} selected={code.dial_code===(this.state.userDetails.country_code!==""?this.state.userDetails.country_code:this.props.settings.country_code)}>{code.name+" ("+code.dial_code+")"}</option>);
												})}
											</Form.Control>
											<Form.Control type="text" name="phone" placeholder="Enter Mobile number" className="required phone" defaultValue={this.state.userDetails.phone}/>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={12}>
										<Form.Group>
											<Form.Label>Address Line 1</Form.Label>
											<Form.Control type="text" name="address" placeholder="Address Line 1" className="required" defaultValue={this.state.userDetails.address}/>
										</Form.Group>
									</Col>
									<Col sm={12}>
										<Form.Group>
											<Form.Label>Address Line 2</Form.Label>
											<Form.Control type="text" name="address2" placeholder="Address Line 2" defaultValue={this.state.userDetails.address2}/>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={6}>
										<Form.Group>
											<Form.Label>City / Town</Form.Label>
											<Form.Control type="text" name="city" placeholder="Enter City" className="required" defaultValue={this.state.userDetails.city}/>
										</Form.Group>
									</Col>
									<Col sm={6}>
										<Form.Group>
											<Form.Label>County / Province / State</Form.Label>
											<Form.Control type="text" name="county" placeholder="Enter County" className="required" defaultValue={this.state.userDetails.county}/>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={6}>
										<Form.Group>
											<Form.Label>Postcode</Form.Label>
											<Form.Control type="text" name="postcode" placeholder="Enter Postcode" className="required" defaultValue={this.state.userDetails.postcode}/>
										</Form.Group>
									</Col>
									<Col sm={6}>
										<Form.Group>
											<Form.Label>Country</Form.Label>
											<Form.Control as="select" name="country" defaultValue={this.getCountry(this.props.settings.country)} className="required" readOnly>
												<option value="">Select Country</option>
												{this.state.countriesList.map((country, index) => {
													let keyIdx = index + 1;
													return (<option value={country.label} key={keyIdx} selected={this.props.settings.country===country.value}>{country.label}</option>);
												})}
											</Form.Control>
										</Form.Group>
									</Col>
								</Row>
								<Form.Group className="text-right">
									<Button type="submit" variant="primary">Update</Button>
								</Form.Group>
							</Form>
						</div>
					</Col>
					<Col md={12}>
						<div id="password-form" className="bg-white rounded shadow-sm p-4">
							<h5 className="text-primary mb-4">Change Password</h5>
            				<div id="errorText" className="d-flex text-danger mb-2">{this.state.formName==="passwordForm"?this.state.errorText:""}</div>
							<Form method="POST" id="changePwd" name="changePwd" onSubmit={this.changePwd}>
								<Row>
									<Col sm={6}>
										<Form.Group>
											<Form.Label>New Password</Form.Label>
											<Form.Control type="password" id="password" name="password" placeholder="Enter New Password" className="required"/> 
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={6}>
										<Form.Group>
											<Form.Label>Confirm Password</Form.Label>
											<Form.Control type="password" id="confirm_password" name="confirm_password" placeholder="Enter Confirm Password" />
										</Form.Group>
									</Col>
								</Row>
								<Form.Group className="text-right">
									<Button type="submit" variant="primary">Submit</Button>
								</Form.Group>
							</Form>
						</div>
					</Col>
				</Row>
		    </>
    	);
    }
}

export default Profile;