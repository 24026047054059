import React from 'react';
import axios from "axios";
import {format} from 'date-fns';
import {Link} from 'react-router-dom';
import {Row,Col,Container,Image,Table} from 'react-bootstrap';
import HeadingValue from './common/HeadingValue';
import PageTitle from './common/PageTitle';
import NotFound from './NotFound';

class Invoice extends React.Component {
	_isMounted = false;

	constructor(props, context) {
	    super(props, context);

	    this.state = {
		  foodCategory: [],
		  foodItems: [],
		  serving: [],
		  sauces: [],
		  order: {id: "", type: "", time: "", subtotal: "", grand_total: "", orderStatus: "", payment: "", note: "", created_at: ""},
		  orderItems: {},
		  customer: {id: "", name: "", email: "", phone: "", address: "", address2: "", city: "", county: "", postcode: "", country: ""},
	    };
	}

	componentDidMount() {
		this._isMounted = true;

		this.getMenusDetails();
		this.getInvoiceDetails();
	}

	componentWillUnmount() {
		this._isMounted = false;
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state,callback)=>{
			return;
		};
	}

	getMenusDetails = () => {
		axios({
            url: this.props.apiUrl+"get-menus-details",
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            }
        })
        .then((res) => {
            if(res.data.status) {
				if(this._isMounted) {
					this.setState({foodCategory: res.data.categories});
					this.setState({foodItems: res.data.menus});
					this.setState({serving: res.data.serving});
					this.setState({sauces: res.data.sauces});
				}
            } else {
                console.log(res.data.message);
            }
        })
        .catch((err) => {
            console.log(err.message);
            console.log("Api error! Please try later.");
        });
	}

	getInvoiceDetails = () => {
		const orderId = this.props.getParams(window.location.href, 1);

		axios({
            url: this.props.apiUrl+"invoice/"+orderId,
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            }
        })
        .then((res) => {
            if(res.data.status) {
				if(this._isMounted) {
					this.setState({order: res.data.order});
					this.setState({orderItems: res.data.orderItems});
					this.setState({customer: res.data.customer});
				}
            } else {
                console.log(res.data.message);
            }
        })
        .catch((err) => {
            console.log(err.message);
            console.log("Api error! Please try later.");
        });
	}

	printWindow = () => {
		let styles = document.querySelectorAll('link[rel="stylesheet"]');
		let styleScript = "";

		styles.forEach(element => {
			styleScript += "<link href="+element.href+" rel='stylesheet'/>";
		});

		let content = document.querySelector("#printContent");
		let mywindow = window.open( "", "Invoice", "height=600,width=800" );
		mywindow.document.write( "<html><head><title></title>" );
		mywindow.document.write( styleScript );
		mywindow.document.write( "</head><body>" );
		mywindow.document.write( content.innerHTML );
		mywindow.document.write( "</body></html>" );
	
		setTimeout(() => {
			mywindow.print();
		}, 100);

		//Close mywindow
		//mywindow.close();
	}

	orderPlace = () => {
		return this.state.order.created_at?format(new Date(this.state.order.created_at), 'dd/MM/yyyy kk:mm:ss'):"";
	}

	orderPickup = () => {
		return (this.state.order.created_at?format(new Date(this.state.order.created_at), 'dd/MM/yyyy'):"")+' '+this.state.order.time+':00';
	}

	customerAddress = () => {
		return this.state.customer.address+(this.state.customer.address2?', '+this.state.customer.address2:'')+', '+this.state.customer.city+', '+this.state.customer.county+', '+this.state.customer.postcode+', '+this.state.customer.country;
	}

	itemExtras = (serves, item) => {
		let extras = serves?serves.name:"";

		if(item.sauces) {
			const saucesids = item.sauces.split(",").map(Number);
			//Commented usused code
			// this.state.sauces.filter((sauce) => {
			// 	const stat = saucesids.includes(sauce.id);
			// 	if(stat) {
			// 		extras += extras?', '+sauce.name:sauce.name;
			// 	}
			// 	return stat;
			// });
			
			this.state.sauces.forEach((sauce) => {
				const stat = saucesids.includes(sauce.id);

				if(stat) {
					extras += extras?', '+sauce.name:sauce.name;
				}
			});
		}

		return extras;
	}

	render() {
		const orderId = this.props.getParams(window.location.href, 1);
		const mediaUrl = this.props.apiUrl.replace("api/", "");

    	return orderId>0?(
    		<>
    			<PageTitle 
	    			title="Invoice"
	    			subTitle=""
	    		/>
		      <section className="section pt-5 pb-5 invoice-section">
		         <Container>					
					<Row>
						<Col md={10} className="mx-auto">
							<div className="p-5 osahan-invoice bg-white shadow-sm">
								<Row>
									<Col md={8} xs={9}>
										<h2 className="mt-0 small-text">Order #{this.state.order.id}</h2>
									</Col>
									<Col md={4} xs={3} className="pl-0 text-right">
										<Link to="#" className="btn btn-primary btn-md" onClick={(e) => {e.preventDefault(); this.printWindow();}}>Print Invoice</Link>
									</Col>
								</Row>
							</div>
						</Col>
					</Row>
                    <div className="pt-4"></div>
		            <Row>
		               <Col md={10} id="printContent" className="mx-auto">
		                  <div className="p-5 osahan-invoice bg-white shadow-sm">
		                     <Row className="mb-5 pb-3">
		                        <Col md={8} xs={9}>
		                           <h4 className="mt-0">Thanks for choosing us! <br/>Here are your order details: </h4>
		                        </Col>
		                        <Col md={4} xs={3} className="pl-0 text-right">
		                           <Image src={mediaUrl + 'images/theme/' + this.props.themeLogo} alt={this.props.themeName} className="invoice-logo" style={{width: "180px"}}/>
		                        </Col>
		                     </Row>
		                     <Row>
		                        <Col md={6}>
		                           <HeadingValue 
		                           		heading='Order No: '
		                           		value={'#'+this.state.order.id}
		                           />
		                           <HeadingValue 
		                           		heading='Order placed at: '
		                           		value={this.orderPlace}
		                           />
		                           <HeadingValue 
		                           		heading='Order pickup at: '
		                           		value={this.orderPickup}
		                           />
		                           <HeadingValue 
		                           		heading='Order Type: '
		                           		value={this.state.order.type}
		                           		valueClassName='text-primary'
		                           />
		                           <HeadingValue 
		                           		heading='Order Status: '
		                           		value={this.state.order.orderStatus}
		                           		valueClassName='text-primary'
		                           />
		                        </Col>
		                        <Col md={6}>
		                           <HeadingValue 
		                           		heading='Customer Details:'
		                           		className="text-black"
		                           />
		                           <HeadingValue 
		                           		className="text-primary"
		                           		value={this.state.customer.name}
		                           />
		                           <HeadingValue 
		                           		heading={this.state.customer.email}
		                           		className="text-black"
		                           />
		                           <HeadingValue 
		                           		heading={'+44 '+this.state.customer.phone}
		                           		className="text-black"
		                           />
		                           <HeadingValue 
		                           		heading={this.customerAddress}
		                           />
		                        </Col>
		                     </Row>
		                     <Row className="mt-5">
		                        <Col md={12}>
		                           <Table className="mt-3 mb-0 invoice-table">
		                              <thead className="thead-light">
		                                 <tr>
		                                    <th className="text-black font-weight-bold" scope="col">Item Name</th>
		                                    <th className="text-black font-weight-bold" scope="col">Extras</th>
		                                    <th className="text-right text-black font-weight-bold" scope="col">Price</th>
		                                    <th className="text-right text-black font-weight-bold" scope="col">Quantity</th>
		                                    <th className="text-right text-black font-weight-bold" scope="col">Total</th>
		                                 </tr>
		                              </thead>
		                              <tbody>
										{this.state.orderItems.length?this.state.orderItems.map((item) => {
											const itemName = this.state.foodItems.find((foodItem) => foodItem.id === +item.menu);
											const serves = this.state.serving.find((serve) => serve.id === +item.serving);
											let extras = this.itemExtras(serves, item);
											let checkItemName = (itemName?itemName.name:"");
											
											return (
											<tr key={item.id}>
												<td>{item.size!==""?(<>{checkItemName+" ("}<span>{item.size}</span>{")"}</>):checkItemName}</td>
												<td>{extras}</td>
												<td className="text-right">{this.props.settings.currency+(parseFloat(item.price).toFixed(2))}</td>
												<td className="text-right">{item.quantity}</td>
												<td className="text-right">{this.props.settings.currency+((item.price * item.quantity).toFixed(2))}</td>
											</tr>
										)}):(<tr><td colSpan={5}></td></tr>)}
		                                 <tr>
		                                    <td className="text-right" colSpan="4">Subtotal:</td>
		                                    <td className="text-right">{this.props.settings.currency+this.state.order.subtotal}</td>
		                                 </tr>
		                                 <tr>
		                                    <td className="text-right" colSpan="4">Tax <span style={{fontSize: "12px"}}>({this.props.settings.taxDesc})</span> ({this.props.settings.vat*100}%):</td>
		                                    <td className="text-right">{this.props.settings.currency+((parseFloat(this.state.order.subtotal)*this.props.settings.vat).toFixed(2))}</td>
		                                 </tr>
		                                 <tr>
		                                    <td className="text-right" colSpan="4"><h6 className="text-primary">Grand Total:</h6></td>
		                                    <td className="text-right"><h6 className="text-primary">{this.props.settings.currency+(+this.state.order.grand_total).toFixed(2)}</h6></td>
		                                 </tr>
		                              </tbody>
		                           </Table>
		                        </Col>
		                     </Row>
		                  </div>
		               </Col>
		            </Row>
		         </Container>
		      </section>
		    </>
    	):(<NotFound/>);
    }
}


export default Invoice;