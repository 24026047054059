import React from 'react';
import axios from "axios";
import {NavLink,Link} from 'react-router-dom';
import {Navbar,Nav,Container,NavDropdown,Image,Form} from 'react-bootstrap';
import Icofont from 'react-icofont';
import CookieConsent from "react-cookie-consent";
import Cookies from 'js-cookie';
import DropDownTitle from '../common/DropDownTitle';
import CartDropdownItem from '../cart/CartDropdownItem';
import LoginModal from '../modals/LoginModal';
import languagesList from './languages.json';

class Header extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
		  languageList: languagesList,
	      isNavExpanded: false,
		  showLoginModal: false,
		  cookieConsent: Cookies.get("restaurantAppCookie") || false,
		  userDetails: localStorage.getItem("cuser")!=null?JSON.parse(localStorage.getItem("cuser")):{id: "", profile_pic: "images/users/user.png", name: "", email: "", phone: "", address: "", address2: "", city: "", county: "", postcode: "", country: ""}
	    };
	}
	hideLoginModal = () => this.setState({ showLoginModal: false });

    setIsNavExpanded = (isNavExpanded) => {
      this.setState({ isNavExpanded: isNavExpanded });
    }

    closeMenu = () => {
      this.setState({ isNavExpanded: false });
    }

    handleClick = (e) => {
      if (this.node.contains(e.target)) {
        // if clicked inside menu do something
      } else {
        // If clicked outside menu, close the navbar.
        this.setState({ isNavExpanded: false });
      }
    }
  
	componentDidMount() {
	    document.addEventListener('click', this.handleClick, false);

		this.checkValidToken();

		const interval = setInterval(this.checkValidToken, 10000);
	  
		return () => {
			clearInterval(interval);
		}
	}

	componentWillUnmount() {
	    document.removeEventListener('click', this.handleClick, false);
	}

	checkValidToken = () => {
		if(localStorage.getItem("clogtoken")!=null) {
			const profileData = JSON.parse(localStorage.getItem("cuser"));

			if(this.state.userDetails.profile_pic != profileData.profile_pic) {
				this.setState({userDetails: JSON.parse(localStorage.getItem("cuser"))});
			}

			axios({
				url: this.props.apiUrl+"dashboard-data",
				method: "GET",
				headers: {
					"Accept": "application/json",
					"Content-Type": "application/json",
					"Authorization": localStorage.getItem("clogtoken")
				}
			})
			.then((res) => {
				if(+res.status >= 400 && +res.status <= 400) {
					this.props.endSession();
				}
			})
			.catch((err) => {
				console.log(err.status+": "+err.message);
				console.log("Api error! Please try later.");
			});
		}
	}
	
	readCookies = () => {
		let cookies = document.cookie;
		let cookieArr = cookies.split("; ");
		let cookieIdx = cookieArr.findIndex(ele => ele.includes("googtrans"));

		if(cookieIdx >= 0) {
			let lang = cookieArr[cookieIdx];
			lang = lang.split("=");
			lang = lang[1].split("/");

			return lang[2];
		} else {
			return null;
		}
	}

	changeLanguage = (lang) => {
		if(document.querySelector(".goog-te-combo") && lang) {
			if(document.querySelector(".goog-te-combo option[value='"+lang+"']")
			&& document.querySelector(".goog-te-combo option[value='"+lang+"']").getAttribute("selected")!==true) {
			  document.querySelector(".goog-te-combo option[value='"+lang+"']").setAttribute("selected", true);
			  document.querySelector(".goog-te-combo").dispatchEvent(new Event('change'));
			}
		}
	}

	render() {
		const mediaUrl = this.props.apiUrl.replace("api/", "");

    	return (
    		<div ref={node => this.node = node}>
			<LoginModal show={this.state.showLoginModal} onHide={this.hideLoginModal}/>
			{!this.state.cookieConsent?<CookieConsent buttonText="Accept Cookies" cookieName="restaurantAppCookie" style={{ fontSize: "14px", fontWeight: "400" }} buttonStyle={{ color: "#ffffff", backgroundColor: "#4169e1", fontSize: "14px", borderRadius: "2px" }} expires={999} debug={true}>We use cookies to optimize site functionality and give you the best possible experience.</CookieConsent>:""}
			<Navbar onToggle={this.setIsNavExpanded}
           expanded={this.state.isNavExpanded} color="light" expand='lg' className="navbar-light osahan-nav shadow-sm">
			   <Container>
			      <Navbar.Brand as={NavLink} to="/home"><Image src={mediaUrl + 'images/theme/' + this.props.themeLogo} alt={this.props.themeName} className="brand-logo"/></Navbar.Brand>
			      <Navbar.Toggle/>
			      <Navbar.Collapse id="navbarNavDropdown">
			         <Nav activeKey={0} className="ml-auto" onSelect={this.closeMenu}>
						<Nav.Link eventKey={0} as={NavLink} exact to="/home">
			            	Home
			            </Nav.Link>
			            <Nav.Link eventKey={1} as={NavLink} exact to="/track-order/0">
             				Track Order
			            </Nav.Link>
			            <Nav.Link eventKey={2} as={NavLink} exact to="/contact-us">
             				Contact Us
			            </Nav.Link>
						{this.props.loggedIn?<NavDropdown alignRight
			            	title={
			            		<DropDownTitle 
			            			className='d-inline-block' 
			            			image={mediaUrl+this.state.userDetails.profile_pic}
			            			imageAlt={this.state.userDetails.name?this.state.userDetails.name:"User"}
			            			imageClass="nav-osahan-pic rounded-pill"
			            			title='My Account'
			            		/>
			            	}
			            >
							<NavDropdown.Item eventKey={4.1} exact as={NavLink} activeclassname="active" to="/my-account/profile"><Icofont icon='user'/> Profile</NavDropdown.Item>
							<NavDropdown.Item eventKey={4.2} exact as={NavLink} activeclassname="active" to="/my-account/orders"><Icofont icon='food-cart'/> Orders</NavDropdown.Item>
							<NavDropdown.Item eventKey={4.3} exact as={NavLink} to="/login" onClick={(e)=>{e.preventDefault(); this.props.logout();}}><Icofont icon='power'/> Logout</NavDropdown.Item>
			            </NavDropdown> : <Nav.Link eventKey={1} as={NavLink} exact to="/login">Login</Nav.Link>}
			            <NavDropdown activeclassname="active" alignRight className="dropdown-cart" 
			            	title={
			            		<DropDownTitle 
			            			className='d-inline-block' 
			            			faIcon='shopping-basket'
			            			iconClass='mr-1'
			            			title='Cart'
			            			badgeClass='ml-1'
			            			badgeVariant='primary'
			            			badgeValue={this.props.cart.length}
			            		/>
			            	}
			            >

			                <div className="dropdown-cart-top shadow-sm">
			                  <div className="dropdown-cart-top-body border-top p-3">
									{this.props.cart.map((item) => (
										<CartDropdownItem 
											key={item.id}
											icoIcon='ui-press'
											iconClass='text-success food-item'
											title={item.title}
											size={item.size}
											quantity={item.quantity}
											price={this.props.settings.currency+(item.price * item.quantity).toFixed(2)}
										/>
									))}
			                  </div>
			                  <div className="dropdown-cart-top-footer border-top p-3">
			                     <p className="mb-0 font-weight-medium text-dark">Sub Total <span className="float-right">{this.props.settings.currency+this.props.cartTotal}</span></p>
			                     <small className="text-primary">Extra charges may apply</small>  
			                  </div>
			                  <div className="dropdown-cart-top-footer border-top p-2">
			                     <NavDropdown.Item eventKey={5.1} as={Link} className={"btn btn-primary btn-block py-3 text-white text-center dropdown-item"+((this.props.curPickTime!=="Closed" && this.props.cart.length>0)?"":" btn-disabled")} to="/checkout" onClick={(e) => {if(!this.props.loggedIn) {e.preventDefault(); this.setState({ showLoginModal: true });} }}>Checkout</NavDropdown.Item>
			                  </div>
			                </div>
			            </NavDropdown>
			         </Nav>
			      </Navbar.Collapse>
				  <div className="lang-wrapper"><Form.Control as="select" name="language" id="language" className="web-language" defaultValue={this.props.settings.language} onChange={(e) => {this.changeLanguage(e.target.value)}}>
						<option value="">Language</option>
						{this.state.languageList.map((lang, index) => {
							let keyIdx = index + 1;
							return (<option value={lang.code} key={keyIdx} selected={(this.readCookies()?this.readCookies():this.props.settings.language)===lang.code}>{lang.lang}</option>);
						})}
					</Form.Control></div>
			   </Container>
			</Navbar>
			</div>
		);
	}
}

export default Header;