import React from 'react';
import axios from "axios";
import {Link, Redirect} from 'react-router-dom';
import {Row,Col,Container,Form,Image} from 'react-bootstrap';
import Icofont from 'react-icofont';
import countryCodesList from './common/country-code.json';

class Register extends React.Component {
	constructor(props, context) {
	    super(props, context);

	    this.state = {
			countryCodeList: countryCodesList,
			errorText: "",
			loginPage: false
	    };
	}

	updateErrorText = (error) => {
		this.setState({errorText: error});
		setTimeout(() => { this.setState({errorText: ""}); }, 5000);
	}

	registerAccount = (e) => {
		e.preventDefault();
		const error = this.props.checkValidation(e.target.elements);
		const formData = new FormData(e.currentTarget);
	
		if(error.indexOf(false) === -1) {
			axios({
				url: this.props.apiUrl+"registration",
				method: "POST",
				contentType: false,
				processType: false,
				headers: {
					"Accept": "application/json",
					"Content-Type": "application/json",
				}, 
				data: formData,
			})
			.then((res) => {
				if(res.data.status) {
					this.updateErrorText("");
					this.props.flashMsg("success", res.data.message);
					setTimeout(() => {
						this.setState({loginPage: true});
					}, 3000);
				} else {
					this.updateErrorText(res.data.message);
				}
			})
			.catch((err) => {
				console.log(err.message);
				this.updateErrorText("Api error! Please try later.");
			});
		} else {
			this.updateErrorText("Please enter user details.");
		}
	}

	selectDialCode = (dialCode) => {
		document.querySelector("#dial_code").textContent = dialCode;
	}

	render() {
		if(this.props.loggedIn) {
			return <Redirect to="/home" />
		}

		if(this.state.loginPage) {
			return <Redirect to="/login" />
		}

    	return (
    	  <Container fluid className='bg-white'>
	         <Row>
	            <Col md={4} lg={6} className="d-none d-md-flex bg-image">
					<Image src="img/login-banner.jpg" alt="" />
				</Col>
	            <Col md={8} lg={6}>
	               <div className="d-flex align-items-center py-5">
	                  <Container>
	                     <Row>
	                        <Col md={9} lg={8} className="mx-auto pl-5 pr-5">
	                           <h3 className="login-heading mb-4">Registration</h3>
            					<div id="errorText" className="d-flex text-danger mb-2">{this.state.errorText}</div>
	                           <Form method="POST" id="registerForm" name="registerForm" onSubmit={this.registerAccount}>
	                              <div className="form-label-group">
	                                 <Form.Control type="text" id="first_name" name="first_name" placeholder="First Name" className="required"/>
	                                 <Form.Label htmlFor="first_name">First Name</Form.Label>
	                              </div>
	                              <div className="form-label-group">
	                                 <Form.Control type="text" id="last_name" name="last_name" placeholder="Last Name" className="required"/>
	                                 <Form.Label htmlFor="last_name">Last Name</Form.Label>
	                              </div>
	                              <div className="form-label-group">
	                                 <Form.Control type="email" id="email" name="email" placeholder="Email address" className="required email"/>
	                                 <Form.Label htmlFor="email">Email address</Form.Label>
	                              </div>
	                              <div className="form-label-group rmobile-field">
								  	 <span><span id="dial_code">{this.props.settings.country_code}</span> <Icofont icon="rounded-down"/></span>
									 <Form.Control as="select" id="country_code" name="country_code" defaultValue={this.props.settings.country_code} onChange={(e) => {this.selectDialCode(e.target.value)}}>
											<option value="">Select Dial Code</option>
											{this.state.countryCodeList.map((code, index) => {
												let keyIdx = index + 1;
												return (<option value={code.dial_code} key={keyIdx} selected={code.dial_code===this.props.settings.country_code}>{code.name+" ("+code.dial_code+")"}</option>);
											})}
									 </Form.Control>
	                                 <Form.Control type="number" id="phone" name="phone" placeholder="Mobile Number" className="required phone"/>
	                                 <Form.Label htmlFor="phone">Mobile Number</Form.Label>
	                              </div>
	                              <div className="form-label-group">
	                                 <Form.Control type="password" id="password" name="password" placeholder="Password" className="required"/>
	                                 <Form.Label htmlFor="password">Password</Form.Label>
	                              </div>
								  <button type="submit" className="btn btn-lg btn-outline-primary btn-block btn-login text-uppercase font-weight-bold mb-2">Sign Up</button>
	                              {/* <Link to="/login" className="btn btn-lg btn-outline-primary btn-block btn-login text-uppercase font-weight-bold mb-2">Sign Up</Link> */}
	                              <div className="text-center pt-3">
	                                 Already have an account? <Link className="font-weight-bold" to="/login">Sign In</Link>
	                              </div>
	                           </Form>
	                        </Col>
	                     </Row>
	                  </Container>
	               </div>
	            </Col>
	         </Row>
	      </Container>
    	);
    }
}


export default Register;