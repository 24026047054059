import React from 'react';
import {Row,Col,Container} from 'react-bootstrap';
import PageTitle from './common/PageTitle';

class TermsConditions extends React.Component {
	render() {
    	return (
    		<>
	    		<PageTitle 
	    			title="Terms and Conditions"
	    			subTitle=""
	    		/>
				<section className="section pt-5 pb-5 bg-white policy-section">
					<Container>
						<Row>
							<Col md={12} sm={12}>
                                <h2 className="text-primary small-text">General Terms and Conditions of Sale</h2>
                                <p>In these Terms and Conditions, unless the context otherwise requires, the following expressions have the following meanings</p>
                                <p>Takeaway/We/Us/Our means {this.props.themeName};</p>
                                <p>FH/us means Foodhub, the trading name of Foodhub Limited. FH are the operators of this Website/Application;</p>
                                <p>Application means www.restaurantapp.com</p>
                                <p>These Terms and Conditions govern the sale of all goods by us and will form the basis of the contract between you and us. If you wish to place an order with us, our Website/Application will guide you through the ordering process. Before submitting your order to us, you will be given the opportunity to review and amend it. Please ensure that you have checked your order and that you have read these Terms and Conditions carefully before submitting it. If you are unsure about any part of these Terms and Conditions, please ask us for clarification.</p>
                                <p></p>
                                <h2 className="text-primary small-text">Our Contract</h2>
                                <p>All orders are subject to availability and confirmation of the order price. Dispatch times may vary according to availability and subject to any delays resulting from postal delays or force majeure for which we will not be responsible. In order to contract with {this.props.themeName} you must be over 18 years of age and possess a valid credit or debit card issued by a bank acceptable to us. {this.props.themeName} retains the right to refuse any request made by you. If your order is accepted we will inform you by email and, SMS or via the website. When placing an order, you undertake that all details you provide to us are true and accurate, that you are an authorised user of the credit or debit card used to place your order and that there are sufficient funds to cover the cost of the goods.</p>
                                <p>When you place an order, you will receive an acknowledgement e-mail and/or webpage confirming receipt of your order: this email and or webpage will only be an acknowledgement and will not constitute acceptance of your order. A contract between us will not be formed until we send you confirmation by the medium used for placing the order (SMS, e-mail or other appropriate digital means) that the goods which you ordered have been dispatched to you. Only those goods listed sent at the time of dispatch will be included in the contract formed.</p>
                                <p></p>
                                <h2 className="text-primary small-text">Pricing and Availability</h2>
                                <p>Whilst we try to ensure that all details, descriptions and prices which appear on this Website/Application are accurate, errors may occur. If we discover an error in the price of any goods which you have ordered we will inform you of this as soon as possible and give you the option of reconfirming your order at the correct price or cancelling it. If we are unable to contact you we will treat the order as cancelled. If you cancel and you have already paid for the goods, you will receive a full refund from {this.props.themeName}. Delivery costs will be charged in addition; such additional charges are clearly displayed where applicable and included in the 'Total Cost'.</p>
                                <p></p>
                                <h2 className="text-primary small-text">Payment</h2>
                                <p>All online card payments are made to Datman LTD. Payments made to Datman LTD will appear on your statement as 'takeaway.je'. Upon receiving your order, we carry out a standard authorisation check on your payment card to ensure there are sufficient funds to fulfil the transaction. Your card will be debited upon authorisation being received. The monies received upon the debiting of your card shall be treated as a full payment against the value of the goods you wish to purchase. Once the goods have been despatched and you have been sent a confirmation email, SMS or notification on the website, the monies paid shall be used for the value of goods you have purchased as listed.</p>
                                <p></p>
                                <h2 className="text-primary small-text">Delivery Estimates</h2>
                                <p>Times given for collection and delivery are only approximate; neither T2S nor the Takeaway can provide an exact time for delivery but endeavour to deliver within the approximate delivery time specified when you place your order.</p>
                                <p></p>
                                <h2 className="text-primary small-text">Refunds & Charge-backs</h2>
                                <p>Online payments made through {this.props.themeName} are handled by Datman LTD . Payments made through Datman LTD will appear on your statement as 'takeaway.je'. For refunds, charge-backs or disputes for online paid orders, please contact us on the details mention below. If an unrecognised transaction appears on your card / statement from Datman LTD, please contact us on the contact details:</p>
                                <p></p>
                                <h2 className="text-primary small-text">Order cancellation and amendments</h2>
                                <p>Once the order has been confirmed and payment taken from your account, you will be unable to cancel your order and will not be eligible for a refund. To change or cancel your order please contact the T2S Customer Support team to attempt to resolve your request. T2S will contact the Takeaway on your behalf but cannot guarantee your request will be accepted as food processing may already be underway.</p>
                                <p></p>
                                <h2 className="text-primary small-text">Compensation</h2>
                                <p>If you are unhappy with the quality of any goods or the service provided by {this.props.themeName} and wish to seek any form of compensation, you should contact {this.props.themeName} directly to raise your complaint and, where appropriate, follow the {this.props.themeName} own complaint procedures. If you are unable to contact the {this.props.themeName}, or the {this.props.themeName} refuses to deal with your complaint, you can contact the T2S Customer Support Team, as the provider of this Website/Application, on +441628477271 within 48 hours of placing your Order and one of the T2S Customer Support Members will attempt to contact the {this.props.themeName} in order to request compensation on your behalf. Please note that T2S has no control over {this.props.themeName} and the quality of the goods or service that they provide, and are not able to provide, and have no responsibility or liability for providing, any compensation to you on behalf of any {this.props.themeName}.</p>
                                <p></p>
                                <h2 className="text-primary small-text">Customer Support</h2>
                                <p>The T2S Customer Support team will attempt to assist you and resolve any enquiry where possible regarding your order. You can contact the T2S Customer Support team by clicking "Contact Us" on the Website/Application and submitting your request via the provided contact form.</p>
                                <p>If you are unhappy with the service provided by FH and wish to make a complaint,  please send an email to complaints@foodhub.com.</p>
                                <p></p>
                                <h2 className="text-primary small-text">Privacy Policy and Terms of Use</h2>
                                <p>Use of our Website/Application is also governed by our Privacy Policy and Website/Application Terms of Use. Our Privacy Policy and Website/Application Terms of Use are incorporated into these Terms and Conditions by this reference.</p>
                                <p></p>
                                <h2 className="text-primary small-text">Variation</h2>
                                <p>{this.props.themeName} shall have the right in its absolute discretion at any time and without notice to amend, remove or vary the Services and/or any page of this Website/Application.</p>
                                <p></p>
                                <h2 className="text-primary small-text">Invalidity</h2>
                                <p>If any of the provisions of these Terms and Conditions are found to be unlawful, invalid or otherwise unenforceable by any court or other authority, that/those provision(s) will be deemed severed from the remainder of these Terms and Conditions and the remainder will be valid and enforceable.</p>
                                <p></p>
                                <h2 className="text-primary small-text">Waiver</h2>
                                <p>If you breach these conditions and we take no action, we will still be entitled to use our rights and remedies in any other situation where you breach these conditions.</p>
                                <p></p>
                                <h2 className="text-primary small-text">Law and Jurisdiction</h2>
                                <p>These Terms and Conditions, and the relationship between you and us (whether contractual or otherwise) shall be governed by and construed in accordance with the laws of England & Wales.</p>
                                <p>If you are a consumer, you will benefit from any mandatory provisions of the law in your country of residence. Nothing in the above clause takes away or reduces your rights as a consumer to rely on those provisions.</p>
                                <p>Any dispute, controversy, proceedings or claim between you and us relating to these Terms and Conditions, or the relationship between you and us (whether contractual or otherwise) shall be subject to the jurisdiction of the courts of England, Wales, Scotland, or Northern Ireland, as determined by your residency.</p>
							</Col>
						</Row>						
					</Container>
				</section>
	    	</>
    	);
    }
}


export default TermsConditions;