import React from 'react';
import {Row,Col,Container} from 'react-bootstrap';
import PageTitle from './common/PageTitle';
import Icofont from 'react-icofont';

class ContactUs extends React.Component {
	days = [{short: "Mon", full: "day"}, {short: "Tue", full: "sday"}, {short: "Wed", full: "nesday"}, {short: "Thu", full: "rsday"}, {short: "Fri", full: "day"}, {short: "Sat", full: "urday"}, {short: "Sun", full: "day"}];

	render() {
    	return (
    		<>
	    		<PageTitle 
	    			title={this.props.themeName}
	    			subTitle=""
	    		/>
				<section className="section pt-5 pb-5 bg-white contact-section">
					<Container>
						<Row>
							<Col md={6} sm={12}>
								<h2 className="m-0 text-primary small-text">Opening Hours</h2>
								<p></p>
								<table>
									<tbody>
										{this.props.settings.openingHours?this.props.settings.openingHours.map((day, index) => {
											const curDate = new Date();
											let curDay = curDate.getDay()===0?7:curDate.getDay();
											let keyIdx = index + 1;

											return (
												<tr className={this.days[curDay - 1].short === day.day? "active" : ""} key={keyIdx}>
													<th>{this.days[index].short+this.days[index].full}</th>
													<td>{day.open} - {day.close}</td>
												</tr>
										)}):(
											<tr>
												<th></th>
												<td></td>
											</tr>
										)}
									</tbody>
								</table>
							</Col>
							<Col md={6} sm={12} className="contact-content">
								<h2 className="m-0 text-primary small-text">Contact Details</h2>
								<p></p>
								<p><a href="#"><Icofont icon='location-pin'/> {this.props.settings.address}</a></p>
								<p><a href="mailto:admin@kasaeshna.com"><Icofont icon='envelope'/> {this.props.settings.email}</a></p>
								<p><a href="tel:+910000000000"><Icofont icon='phone'/> {this.props.settings.country_code+this.props.settings.phone}</a></p>
							</Col>
						</Row>						
					</Container>
				</section>
	    	</>
    	);
    }
}


export default ContactUs;