import React from 'react';
import {Link} from 'react-router-dom';
import {Modal,Image} from 'react-bootstrap';
import Icofont from 'react-icofont';

class LoginModal extends React.Component {

	render() {
    	return (
	        <Modal 
	        	show={this.props.show} 
	        	onHide={this.props.onHide}
		        size="sm"
		        centered
		   	  >

			  <Modal.Header closeButton={true}>
			    <Modal.Title as='h6'>
		            <Image alt="logo" src="img/favicon.png" className="modal-logo"/> {this.props.themeName}
                </Modal.Title>
			  </Modal.Header>

			  <Modal.Body>
                <h6><Icofont icon="lock" className="icon-bg"/> <Link to="/login" onClick={(e) => {if(!this.props.loggedIn) {this.props.onHide();} }}>Login as Customer</Link></h6>
                <div className="pt-1"></div>
                <h6><Icofont icon="user" className="icon-bg"/> <Link to="/checkout" onClick={(e) => {if(!this.props.loggedIn) {this.props.onHide();} }}>Login as Guest</Link></h6>
			  </Modal.Body>
			</Modal>
    	);
    }
}
export default LoginModal;